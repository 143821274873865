import axios from "axios";
import { APIBaseURL } from "config/Config";


export const withAuthCheck = (apiCall) => {
      return async function (...args) {
            try {
                  const response = await apiCall(...args);
                  if (response?.data?.statusCode == "401") {
                        localStorage.clear();
                        window.location.pathname = "/authentication/sign-in";
                  } else {
                        return response;
                  }
            } catch (error) {
                  console.log(error);
            }
      };
};
// const headers = {
//   "Content-Type": "application/json",
//   apikey: ApiKey,
// };

export const UsersService = {
      UserList: withAuthCheck(async function (role) {
            const requestBody = {
                  role: role,
            };
            const response = await axios.post(`${APIBaseURL}/adminapi/rolewise-user`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            })
            return response;
      }),
      AdminList: withAuthCheck(async function (role) {
            const response = await axios.get(`${APIBaseURL}/adminapi/getAllAdmins`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            })
            return response;
      }),
      Session: withAuthCheck(async function (role) {
            const response = await axios.get(`${APIBaseURL}/adminapi/mentor-session`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            })
            return response;
      }),
      isUserLoggedIn: function () {
            return !!localStorage.getItem("access_token");
      },
      ActivateDactivateUser: withAuthCheck(async function (id, status) {
            const requestBody = {
                  id: id, status: status.toString()
            };
            const response = await axios.post(`${APIBaseURL}/adminapi/user-statusupdate`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      ActivateDactivateAdmin: withAuthCheck(async function (id, status) {
            const requestBody = {
                  id: id, status: status.toString()
            };
            const response = await axios.post(`${APIBaseURL}/adminapi/admin-status-update`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      getSettings: withAuthCheck(async function (id, status) {

            const response = await axios.get(`${APIBaseURL}/adminapi/get-setting`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      postSettings: withAuthCheck(async function (data) {
            const requestBody = data;
            const response = await axios.post(`${APIBaseURL}/adminapi/post-setting`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      createAdmin: withAuthCheck(async function (data) {
            const requestBody = data;
            const response = await axios.post(`${APIBaseURL}/adminapi/register`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      getCompany: withAuthCheck(async function (data) {
            const requestBody = data;
            const response = await axios.post(`${APIBaseURL}/adminapi/company-list`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      getJobs: withAuthCheck(async function (id, status) {

            const response = await axios.get(`${APIBaseURL}/adminapi/job-list`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      createCompany: withAuthCheck(async function (data) {
            const requestBody = data;
            const response = await axios.post(`${APIBaseURL}/adminapi/post-company`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),

      getUniversity: withAuthCheck(async function (data) {
            const requestBody = data;
            const response = await axios.post(`${APIBaseURL}/adminapi/university-list`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),

      createUniversity: withAuthCheck(async function (data) {
            const requestBody = data;
            const response = await axios.post(`${APIBaseURL}/adminapi/post-university`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),

      deleteUniversity: withAuthCheck(async function (data) {
            const requestBody = { id: data };
            const response = await axios.post(`${APIBaseURL}/adminapi/university-delete`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),

      deleteCompany: withAuthCheck(async function (data) {
            const requestBody = { id: data };
            const response = await axios.post(`${APIBaseURL}/adminapi/company-delete`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      deleteAdmin: withAuthCheck(async function (id, status) {
            const requestBody = { id: id, status: status };
            const response = await axios.post(`${APIBaseURL}/adminapi/admin-status-update`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      deleteJob: withAuthCheck(async function (data) {
            const requestBody = { id: data };
            const response = await axios.post(`${APIBaseURL}/adminapi/company-delete`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      ActivateDactivateAdmin: withAuthCheck(async function (id, status) {
            const requestBody = {
                  id: id, status: status.toString()
            };
            const response = await axios.post(`${APIBaseURL}/adminapi/admin-status-update`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      ChangeStatusCompany: withAuthCheck(async function (data) {

            const response = await axios.post(`${APIBaseURL}/adminapi/post-company`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      ChangeStatusUniversity: withAuthCheck(async function (data) {

            const response = await axios.post(`${APIBaseURL}/adminapi/post-university`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      getDashboardGraphData: withAuthCheck(async function (data) {
            const requestBody = data;
            const response = await axios.post(`${APIBaseURL}/adminapi/admin-dashboard`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      SaveInterviewGuide: withAuthCheck(async function (data) {

            const response = await axios.post(`${APIBaseURL}/adminapi/save-interview-guide`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      getInterviewGuide: withAuthCheck(async function (id, status) {

            const response = await axios.get(`${APIBaseURL}/adminapi/interview-guide-list`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      InterviewGuideDeleted: withAuthCheck(async function (data) {

            const response = await axios.post(`${APIBaseURL}/adminapi/interview-guide-delete`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      SaveTrainingCoarse: withAuthCheck(async function (data) {

            const response = await axios.post(`${APIBaseURL}/adminapi/save-course`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      getTrainingCoarse: withAuthCheck(async function (id, status) {

            const response = await axios.get(`${APIBaseURL}/adminapi/interview-course-list`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      deleteCoarse: withAuthCheck(async function (data) {
            const requestBody = { id: data };
            const response = await axios.post(`${APIBaseURL}/adminapi/course-delete`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      saveCourseClasses: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/save-course-classes`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      updateCourseClasses: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/update-course-classes`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      updateCourse: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/update-course`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      updateUserDetail: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/user-profile-update`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      viewCourseClasses: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/course-classes-list`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      deleteClass: withAuthCheck(async function (data) {
            const requestBody = { id: data };
            const response = await axios.post(`${APIBaseURL}/adminapi/course-classes-delete`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      viewClassMCQ: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/course-mcqs-list`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      saveCourseClassesMCQ: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/save-course-mcqs`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      deleteClassMCQ: withAuthCheck(async function (data) {
            const requestBody = { id: data };
            const response = await axios.post(`${APIBaseURL}/adminapi/course-mcqs-delete`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      getSupportData: withAuthCheck(async function (id, status) {

            const response = await axios.get(`${APIBaseURL}/adminapi/get-support-data`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      saveFAQ: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/save-faq`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      viewFAQ: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/faq-list`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      viewPrivacyPolicy: withAuthCheck(async function (data) {
            const response = await axios.get(`${APIBaseURL}/adminapi/privacy-policy`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      updatePrivacyPolicy: withAuthCheck(async function (data) {
            const requestBody = {
                  id: 1,
                  title: "Privacy Policy",
                  desciption: data
            };
            const response = await axios.post(`${APIBaseURL}/adminapi/update-privacy-policy`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      viewTermsAndCondition: withAuthCheck(async function (data) {
            const response = await axios.get(`${APIBaseURL}/adminapi/terms-and-condition`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      updateTermsAndCondition: withAuthCheck(async function (data) {
            const requestBody = {
                  id: 1,
                  title: "terms and condition",
                  desciption: data
            };
            const response = await axios.post(`${APIBaseURL}/adminapi/update-terms-and-condition`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      updateCoarseReview: withAuthCheck(async function (data) {
            const requestBody = {
                  id: data,
                  // title: "Privacy Policy",
                  // desciption: data
            };
            const response = await axios.post(`${APIBaseURL}/adminapi/course-review-update`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),

      updateCourseStatus: withAuthCheck(async function (data) {

            const response = await axios.post(`${APIBaseURL}/adminapi/course-review-update`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      getCoarseReview: withAuthCheck(async function (data) {
            const requestBody = {
                  // id: data,
                  // title: "Privacy Policy",
                  // desciption: data
            };
            const response = await axios.post(`${APIBaseURL}/adminapi/course-review-list`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      deleteFAQ: withAuthCheck(async function (data) {
            const requestBody = { id: data };
            const response = await axios.post(`${APIBaseURL}/adminapi/faq-delete`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      viewContactus: withAuthCheck(async function () {
            const response = await axios.get(`${APIBaseURL}/adminapi/get-contact-us`, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      deleteContactUs: withAuthCheck(async function (data) {
            const requestBody = { id: data };
            const response = await axios.post(`${APIBaseURL}/adminapi/contact-us-delete`, requestBody, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
      submitReplyQuery: withAuthCheck(async function (data) {
            const response = await axios.post(`${APIBaseURL}/adminapi/support-reply`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),

      resetPassword: withAuthCheck(async function (data) {
            const requestBody = { data };
            const response = await axios.post(`${APIBaseURL}/adminapi/reset-password`, data, {
                  headers: {
                        authorization: `Bearer ${localStorage.getItem("access_token")}`
                  }
            });
            return response;
      }),
};

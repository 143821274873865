import React from 'react'
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';


const Logout = () => {
      const history = useNavigate();

      // Function you want to call
      const handleLogOut = () => {
            console.log("User is logging out...");
            localStorage.clear()
            // Add any other logout logic here, like clearing tokens, etc.

            // Redirect to sign-in page
            history('/authentication/sign-in');
            toast.success('Logged Out!', {
                  position: "top-right"
            })
      };

      React.useEffect(() => {
            handleLogOut();
      }, []);
      return (
            <div><Toaster />Logout</div>
      )
}

export default Logout
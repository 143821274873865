// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {
      CAvatar,
      CButton,
      CCard,
      CCardBody,
      CCardSubtitle,
      CCardTitle,
      CCol,
      CDropdown,
      CDropdownItem,
      CDropdownMenu,
      CDropdownToggle,
      CProgress,
      CRow,
      // CCardHeader,
      // CTable,
      // CTableBody,
      // CTableDataCell,
      // CTableHead,
      // CTableHeaderCell,
      // CTableRow,
      // CWidgetStatsA,
      // CForm,
      // CFormLabel,
      // CFormInput,
      // CFormText,
      // CFormCheck,
      // CModal,
      // CModalHeader,
      // CModalBody,
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import { FormControl, FormLabel } from '@mui/material';
import { useState, useEffect } from "react";
import { UsersService } from "services/UsersAuth";
import toast, { Toaster } from 'react-hot-toast';
import RichEditor from "layouts/trainingProgramClass/Editor";





function PrivacyPolicy() {

      const [privacy, setPrivacy] = useState("");


      useEffect(() => {
            getPrivacyDetail()
      }, [])





      const getPrivacyDetail = async () => {
            const privacyData = await UsersService.viewPrivacyPolicy();
            if (privacyData.status === 200) {
                  setPrivacy(privacyData?.data?.data?.desciption)
            }

      }


      const postSettingDetail = async (e) => {
            e.preventDefault()
            const updateTerms = await UsersService.updatePrivacyPolicy(privacy);
            if (updateTerms.status === 200) {
                  getPrivacyDetail()
                  toast.success('Successfully Created!', {
                        position: "top-right"
                  })
            }
      }


      const onTextChange = (value, editor) => {
            setPrivacy(value);
      }




      return (
            <DashboardLayout>
                  <DashboardNavbar />
                  <Toaster />
                  <CRow>
                        <CCol xs={12}>
                              <CCard className="mb-4">

                                    <CCardBody>
                                          <form>
                                                <FormLabel>Privacy Policy</FormLabel>
                                                <RichEditor
                                                      editorDefaultText={privacy}
                                                      handleUpdate={onTextChange}
                                                ></RichEditor>

                                                <br />
                                                <br />
                                                <Button
                                                      variant="contained"
                                                      color="primary"
                                                      type="submit"
                                                      onClick={(e) => { postSettingDetail(e) }}
                                                >
                                                      Submit
                                                </Button>
                                          </form>
                                    </CCardBody>
                              </CCard>
                        </CCol>
                  </CRow>
                  <Footer />
            </DashboardLayout>
      );
}

export default PrivacyPolicy;


import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
const AutoLogout = ({ timeout = 150 * 60 * 1000 }) => {
      const timerRef = useRef(null);
      const navigate = useNavigate();
      const logout = () => {
            localStorage.clear();
            navigate('/authentication/sign-in');
            window.location.reload();
      };
      const resetTimer = () => {
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(logout, timeout);
      };
      useEffect(() => {
            const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];
            events.forEach((event) => window.addEventListener(event, resetTimer));
            resetTimer();
            return () => {
                  events.forEach((event) => window.removeEventListener(event, resetTimer));
                  if (timerRef.current) clearTimeout(timerRef.current);
            };
      }, [timeout]);
      return null;
};
export default AutoLogout;
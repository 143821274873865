import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
const SoftPieChart = ({ data }) => {


      return (
            <PieChart
                  series={[
                        {
                              data,
                              paddingAngle: -1,
                              innerRadius: 12,
                              highlightScope: { faded: 'global', highlighted: 'item' },
                              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                        },
                  ]}
                  height={200}
            />
      )
}

export default SoftPieChart





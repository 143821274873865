// import * as React from 'react';
// import { FormControl, useFormControlContext } from '@mui/base/FormControl';
// import { Input, inputClasses } from '@mui/base/Input';
// import { styled } from '@mui/system';
// import clsx from 'clsx';

// export default function BasicFormControl() {
//   return (
//     <FormControl defaultValue="" required>
//       <Label>Name</Label>
//       <StyledInput placeholder="Write your name here" />
//       <HelperText />
//     </FormControl>
//   );
// }

// const StyledInput = styled(Input)(
//   ({ theme }) => `

//   .${inputClasses.input} {
//     width: 320px;
//     font-family: 'IBM Plex Sans', sans-serif;
//     font-size: 0.875rem;
//     font-weight: 400;
//     line-height: 1.5;
//     padding: 8px 12px;
//     border-radius: 8px;
//     color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
//     background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
//     border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
//     box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

//     &:hover {
//       border-color: ${blue[400]};
//     }

//     &:focus {
//       outline: 0;
//       border-color: ${blue[400]};
//       box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
//     }
//   }
// `,
// );

// const Label = styled(
//   ({ children, className }: { children ?: React.ReactNode; className?: string }) => {
//     const formControlContext = useFormControlContext();
//     const [dirty, setDirty] = React.useState(false);

//     React.useEffect(() => {
//       if (formControlContext?.filled) {
//         setDirty(true);
//       }
//     }, [formControlContext]);

//     if (formControlContext === undefined) {
//       return <p>{children}</p>;
//     }

//     const { error, required, filled } = formControlContext;
//     const showRequiredError = dirty && required && !filled;

//     return (
//       <p className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
//         {children}
//         {required ? ' *' : ''}
//       </p>
//     );
//   },
// )`
//   font-family: 'IBM Plex Sans', sans-serif;
//   font-size: 0.875rem;
//   margin-bottom: 4px;

//   &.invalid {
//     color: red;
//   }
// `;

// const HelperText = styled((props: {}) => {
//   const formControlContext = useFormControlContext();
//   const [dirty, setDirty] = React.useState(false);

//   React.useEffect(() => {
//     if (formControlContext?.filled) {
//       setDirty(true);
//     }
//   }, [formControlContext]);

//   if (formControlContext === undefined) {
//     return null;
//   }

//   const { required, filled } = formControlContext;
//   const showRequiredError = dirty && required && !filled;

//   return showRequiredError ? <p {...props}>This field is required.</p> : null;
// })`
//   font-family: 'IBM Plex Sans', sans-serif;
//   font-size: 0.875rem;
// `;

// const blue = {
//   100: '#DAECFF',
//   200: '#b6daff',
//   400: '#3399FF',
//   500: '#007FFF',
//   600: '#0072E5',
//   900: '#003A75',
// };

// const grey = {
//   50: '#F3F6F9',
//   100: '#E5EAF2',
//   200: '#DAE2ED',
//   300: '#C7D0DD',
//   400: '#B0B8C4',
//   500: '#9DA8B7',
//   600: '#6B7A90',
//   700: '#434D5B',
//   800: '#303740',
//   900: '#1C2025',
// };



// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress,
  CRow,
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
// import usersTableData from "layouts/tables/data/usersTableData";
import MyForm from "./form/index"
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import { FormControl, FormLabel } from '@mui/material';
import { useState, useEffect } from "react";
import { UsersService } from "services/UsersAuth";
import toast, { Toaster } from 'react-hot-toast';
import OtpInput from "components/otp";





function Tables() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  // const { columns: urCols, rows: urRows } = usersTableData;
  const userEmail = JSON.parse(localStorage.getItem("user_data"))
  const [settings, setSettings] = useState({
    email: userEmail?.email,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  })
  const [settingData, setSettingData] = useState();
  const [checkData, setCheckData] = useState(true);
  const [otp, setOtp] = useState();
  const [steps, setSteps] = useState(1);

  // console.log("user_data to be added", userEmail)
  useEffect(() => {
    getSettingDetail()
  }, [])




  const getSettingDetail = async () => {
    const adminList = await UsersService.getSettings();
    setSettingData(adminList?.data?.data)
  }


  const ValidatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&-]{11,}$/;
    return regex.test(password);
  };


  const postSettingDetail = async (e) => {
    e.preventDefault()
    let Apidata = {
      email: settings.email ? settings.email : null,
      password: settings?.newPassword ? settings?.newPassword : null,
    }
    if (settings?.newPassword == settings?.confirmNewPassword) {
      const adminList = await UsersService.resetPassword(Apidata);
      setSteps(1)
      getSettingDetail()
      toast.success('Successfully Updated!', {
        position: "top-right"
      })
    } else {
      toast.error('New Password and Confirm Password does not matched', {
        position: "top-right"
      })
    }

  }


  const sendOTP = async (e) => {
    // console.log("formattedNumberformattedNumber", formattedNumber);
    e.preventDefault()
    try {
      const response = await fetch('https://rjback.freshgrad.com/adminapi/send-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "type": "resetpassword", email: settings.email, password: settings.newPassword }),
      });

      const result = await response.json();

      if (result.statusCode == 200) {
        if (settings?.newPassword == settings?.confirmNewPassword) {
          toast.success(result?.msg);
          setSteps(2)
        } else {
          toast.error('New Password and Confirm Password does not matched.');
        }
      } else {
        // alert('Failed to send OTP.');
        toast.error('Failed to send OTP.');
      }
    } catch (error) {
      console.error(error);
      // alert('An error occurred.');
      toast.error('Something went wrong');
      // setSteps(2)
    }
  };


  const verifyOTP = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch('https://rjback.freshgrad.com/adminapi/confirm-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "type": "resetpassword",
          email: settings.email,
          otp: otp,
        }),
      });

      const result = await response.json();
      // console.log("result", result);

      if (result?.data?.isOtpMatch == true) {
        // alert('OTP verified!');
        toast.success('OTP verified!');
        postSettingDetail(e)
        // setSteps(3)
      } else {
        // alert(result.message || 'Failed to verify OTP.');
        toast.error(result?.msg);
      }
    } catch (error) {
      console.error(error);
      // alert('An error occurred.');
      toast.error('Something went wrong');
      // setSteps(3)
    }
  };





  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">

            <CCardBody>
              <form>
                {steps == 1 && <> <FormLabel>Email</FormLabel>
                  <TextField
                    className="inside_input"
                    value={settings.email}
                    variant="outlined"
                    fontSize="10px"
                    fullWidth={true}
                    margin="normal"
                    type="email"
                  />

                  <FormLabel>New Password</FormLabel>
                  <TextField
                    className="inside_input"
                    onChange={(e) => {
                      setSettings((prevSettings) => ({
                        ...prevSettings,
                        newPassword: e.target.value
                      }));
                      setCheckData(ValidatePassword(e.target.value));
                    }}
                    value={settings.newPassword}
                    variant="outlined"
                    fontSize="10px"
                    fullWidth={true}
                    margin="normal"
                    type="password"
                  />
                  {!checkData && <p style={{ color: "red" }}>Password must be at least 11 characters long,
                    contain at least one lowercase letter, one
                    uppercase letter, one digit, and one special
                    character (@$!%*?&).</p>}

                  <FormLabel>Confirm New Password</FormLabel>
                  <TextField
                    className="inside_input"
                    onChange={(e) => {
                      setSettings((prevSettings) => ({
                        ...prevSettings,
                        confirmNewPassword: e.target.value
                      }));
                    }}
                    value={settings.confirmNewPassword}
                    variant="outlined"
                    fontSize="10px"
                    fullWidth={true}
                    margin="normal"
                    type="password"
                  /></>}

                {steps == 2 && <><FormLabel>Enter Otp</FormLabel>
                  <br />
                  <br />
                  <OtpInput length={6} onChange={setOtp} /></>}

                <br />
                {steps == 1 ? <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={(e) => { sendOTP(e) }}
                >
                  Send Otp
                </Button> : <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={(e) => { verifyOTP(e) }}
                >
                  Verify Otp
                </Button>}
              </form>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {/* <FormControl>
        <FormLabel>Enter Name</FormLabel>
        <TextField fullWidth></TextField>
        <Button>Submit</Button>
      </FormControl> */}

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;


import React, { useState } from 'react'
import { CBadge, CButton, CCardBody, CCollapse, CSmartTable } from '@coreui/react-pro'

import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { RiDeleteBin5Line } from 'react-icons/ri';
import { FiCheckCircle } from "react-icons/fi";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";


const RecruiterTableData = ({ columns, data, changeStatusConfirmation, startDate, endDate, RecruiterFormPopup }) => {


  // function gg() {
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, utils.json_to_sheet(data));
  //   writeFileXLSX(wb, "data.xlsx");
  // }
  const [details, setDetails] = useState([])
  // const columns = [
  //   { key: 'first_name', _style: { width: '15%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'last_name', _style: { width: '15%', fontSize: '16px'}, filter: false, sorter: false, },
  //   { key: 'company_name', _style: { width: '15%', fontSize: '16px'}, filter: false, sorter: false, },
  //   { key: 'email_id', _style: { width: '15%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'Phone_Number', _style: { width: '20%', fontSize: '16px' }, filter: false, sorter: false, },
  //   // { key: 'Profile Request', _style: { width: '20%', fontSize: '16px'}, filter: false, sorter: false, },
  //   // { key: 'registered', _style: { width: '20%' },filter: false,  sorter: false,},
  //   // { key: 'Location', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
  //   // { key: 'status', _style: { width: '30%' }, filter: false, sorter: false, },
  //   { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
  // ]
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 'Inactive':
        return 'secondary'
      case 'Pending':
        return 'warning'
      case 0:
        return 'danger'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  return (
    <>
      {/* <button onClick={gg}>EXPORT DATA</button> */}
      <CSmartTable
        checkboxSelection
        sorterValue={{ column: 'name', state: 'asc' }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
          className: 'add-this-class',
          responsive: true,
        }}
        activePage={1}
        // footer
        action
        items={data}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={5}
        // columnFilterValue={{
        //   created_at: (date) =>
        //     new Date(Date.parse(date)) >= startDate && new Date(Date.parse(date)) <= endDate,
        // }}
        columnSorter
        pagination
        scopedColumns={{
          created_at: (item) => (
            <td>
              <CCardBody >
                {moment(item.created_at).format("MM/DD/YYYY")}
              </CCardBody>
            </td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.status)} onClick={() => { changeStatusConfirmation(item.id, item.status == 1 ? 2 : 1) }} >{item.status == 1 ? 'Active' : 'Dactive'}</CBadge>
            </td>
          ),
          dob: (item) => (
            <td>
              <CCardBody >
                {moment(item?.recruiter_detail?.DOB).format("MM/DD/YYYY")}
              </CCardBody>
            </td>
          ),
          Nationality: (item) => (
            <td>
              <CCardBody >
                {item?.recruiter_detail?.nationality}
              </CCardBody>
            </td>
          ),
          show_details: (item) => {
            return (

              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(item.id)
                  }}
                >
                  {details.includes(item.id) ? < BiSolidShow /> : <BiSolidHide />}
                </CButton>
              </td>
            )
          },
          details: (item) => {
            return (

              <CCollapse visible={details.includes(item.id)}>
                <CCardBody >
                  <h6>{item.username}</h6>
                  <p className="text-muted" style={{ fontSize: '14px' }}>User since: {item.registered}</p>
                  <CButton size="sm" color="info" onClick={() => { RecruiterFormPopup(item) }}>
                    {/* <FiCheckCircle /> */}
                    <FaRegEdit />
                  </CButton>
                  {/* <CButton size="sm" color="danger" className="ml-1">
                    <RiDeleteBin5Line />
                  </CButton> */}
                </CCardBody>
              </CCollapse>
            )
          },
        }}


        tableBodyProps={{
          className: 'align-middle',
          style: { fontSize: '13px' }
        }}
      />
    </>
  )
}

export default RecruiterTableData

import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress,
  CRow,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CWidgetStatsA,
  CForm,
  CFormLabel,
  CFormInput,
  CFormText,
  CFormCheck,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CDateRangePicker
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { utils, writeFileXLSX } from "xlsx";

// Data

import TrainerTableData from "./Data/TrainerTableData";
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { useEffect, useState } from "react";
import { UsersService } from "services/UsersAuth";
import MentorEditForm from "./Data/MentorEditForm";

function Tables() {

  const [trainers, setTrainers] = useState();
  const [visible, setVisible] = useState(false)
  const [selectedTrainer, setSelectedTrainer] = useState({ id: "", status: "" })
  const [startDate, setStartDate] = useState(new Date('2022-01-01'))
  const [endDate, setEndDate] = useState(new Date('2022-03-19'))
  const [editableData, setEditableData] = useState();
  const [VisibleForm, setVisibleForm] = useState(false)
  const minDate = new Date(
    Math.min(
      trainers?.map((element) => {
        return new Date(Date.parse(element.created_at))
      }),
    ),
  )
  const maxDate = new Date(
    Math.max(
      trainers?.map((element) => {
        return new Date(Date.parse(element.created_at))
      }),
    ),
  )

  const MentorFormPopup = async (data) => {
    setEditableData(data)
    setVisibleForm(true)
  }

  const closeEditForm = () => {
    setVisibleForm(false)
  }

  useEffect(() => {
    getUsersDetail()
  }, [])
  // useEffect(() => {
  //   setStartDate(minDate)
  //   setEndDate(maxDate)
  // }, [trainers])

  const getUsersDetail = async () => {
    const studentList = await UsersService?.UserList(3);
    setTrainers(studentList?.data?.data)
  }

  const changeStatus = async () => {
    await UsersService.ActivateDactivateUser(selectedTrainer.id, selectedTrainer.status)
    setVisible(false)
    await getUsersDetail()
  }

  const changeStatusOfTrainer = (id, status) => {
    setSelectedTrainer({ id, status })
    setVisible(true)
  }

  const statusFilterFunction = (item, filter) => {
    const statusText = item.status === 1 ? 'Active' : 'Deactive';
    return statusText.toLowerCase().includes(filter.toLowerCase());
  };

  const columns = [
    { key: 'status', label: "status(1=active/2=deactive)", _style: { width: '10%', fontSize: '16px' }, filter: true, filterFunction: () => { statusFilterFunction() }, sorter: false, },
    { key: 'id', _style: { width: '4px', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'fname', _style: { width: '10px', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'lname', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'email', _style: { width: '18%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'mobile', _style: { width: '14%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'citizen', _style: { width: '18%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'country', _style: { width: '18%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'per_hour_price', label: "Hour fees", _style: { width: '14%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'state', _style: { width: '18%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'city', _style: { width: '14%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'job_title', _style: { width: '14%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'linkdin', _style: { width: '14%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'nationality', _style: { width: '14%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'dob', _style: { width: '14%', fontSize: '16px' }, filter: false, sorter: true, },
    { key: 'user_type', _style: { width: '14%', fontSize: '16px' }, filter: true, sorter: true, },
    {
      key: 'created_at', _style: { width: '20%', fontSize: '16px' }, filter: (values, onChange) => {
        return (
          <CDateRangePicker
            footer
            size="sm"
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onStartDateChange={(date) => {
              setStartDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return endDate ? itemDate >= date && itemDate <= endDate : itemDate >= date
                }
                return true
              })
            }}
            onEndDateChange={(date) => {
              setEndDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return startDate ? itemDate <= date && itemDate >= startDate : itemDate <= date
                }
                return true
              })
            }}
          />
        )
      }, sorter: true,
    },
    // { key: 'start_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
    // { key: 'end_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
    // { key: 'role', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
    { key: 'show_details', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
  ]



  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <CCardHeader>
          {/* <strong>ADMIN USER DATA</strong> <small></small> */}
        </CCardHeader>

        <SoftBox py={3}>

          <CCardHeader>
            <strong>Total Trainers Registered</strong> <small></small>

          </CCardHeader>
          <CRow>
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardBody style={{ overflowX: 'auto' }}>
                  <TrainerTableData columns={columns} data={trainers} changeStatus={changeStatus} changeStatusOfTrainer={changeStatusOfTrainer} startDate={startDate} endDate={endDate} MentorFormPopup={MentorFormPopup} />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </SoftBox>
        <Footer />
      </DashboardLayout>
      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to {selectedTrainer.status == 1 ? "active" : "deactive"} trainer!</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            No
          </CButton>
          <CButton color="primary" onClick={() => changeStatus()}>Yes</CButton>
        </CModalFooter>
      </CModal>



      <CModal
        visible={VisibleForm}
        onClose={() => setVisibleForm(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        {/* <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Edit Student Detail</CModalTitle>
        </CModalHeader> */}
        <CModalBody style={{ height: '400px', overflowY: 'scroll' }}>
          <MentorEditForm data={editableData} closeEditForm={closeEditForm} getUsersDetail={getUsersDetail} />
        </CModalBody>
        <CModalFooter>
          {/* <CButton color="secondary" onClick={() => setVisibleForm(false)}>
            No
          </CButton> */}
          <CButton color="primary" onClick={() => { setVisibleForm(false) }}>Close</CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default Tables;


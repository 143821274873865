// import * as React from 'react';
// import { FormControl, useFormControlContext } from '@mui/base/FormControl';
// import { Input, inputClasses } from '@mui/base/Input';
// import { styled } from '@mui/system';
// import clsx from 'clsx';

// export default function BasicFormControl() {
//   return (
//     <FormControl defaultValue="" required>
//       <Label>Name</Label>
//       <StyledInput placeholder="Write your name here" />
//       <HelperText />
//     </FormControl>
//   );
// }

// const StyledInput = styled(Input)(
//   ({ theme }) => `

//   .${inputClasses.input} {
//     width: 320px;
//     font-family: 'IBM Plex Sans', sans-serif;
//     font-size: 0.875rem;
//     font-weight: 400;
//     line-height: 1.5;
//     padding: 8px 12px;
//     border-radius: 8px;
//     color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
//     background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
//     border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
//     box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

//     &:hover {
//       border-color: ${blue[400]};
//     }

//     &:focus {
//       outline: 0;
//       border-color: ${blue[400]};
//       box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
//     }
//   }
// `,
// );

// const Label = styled(
//   ({ children, className }: { children ?: React.ReactNode; className?: string }) => {
//     const formControlContext = useFormControlContext();
//     const [dirty, setDirty] = React.useState(false);

//     React.useEffect(() => {
//       if (formControlContext?.filled) {
//         setDirty(true);
//       }
//     }, [formControlContext]);

//     if (formControlContext === undefined) {
//       return <p>{children}</p>;
//     }

//     const { error, required, filled } = formControlContext;
//     const showRequiredError = dirty && required && !filled;

//     return (
//       <p className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
//         {children}
//         {required ? ' *' : ''}
//       </p>
//     );
//   },
// )`
//   font-family: 'IBM Plex Sans', sans-serif;
//   font-size: 0.875rem;
//   margin-bottom: 4px;

//   &.invalid {
//     color: red;
//   }
// `;

// const HelperText = styled((props: {}) => {
//   const formControlContext = useFormControlContext();
//   const [dirty, setDirty] = React.useState(false);

//   React.useEffect(() => {
//     if (formControlContext?.filled) {
//       setDirty(true);
//     }
//   }, [formControlContext]);

//   if (formControlContext === undefined) {
//     return null;
//   }

//   const { required, filled } = formControlContext;
//   const showRequiredError = dirty && required && !filled;

//   return showRequiredError ? <p {...props}>This field is required.</p> : null;
// })`
//   font-family: 'IBM Plex Sans', sans-serif;
//   font-size: 0.875rem;
// `;

// const blue = {
//   100: '#DAECFF',
//   200: '#b6daff',
//   400: '#3399FF',
//   500: '#007FFF',
//   600: '#0072E5',
//   900: '#003A75',
// };

// const grey = {
//   50: '#F3F6F9',
//   100: '#E5EAF2',
//   200: '#DAE2ED',
//   300: '#C7D0DD',
//   400: '#B0B8C4',
//   500: '#9DA8B7',
//   600: '#6B7A90',
//   700: '#434D5B',
//   800: '#303740',
//   900: '#1C2025',
// };
















/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress,
  CRow,
  // CCardHeader,
  // CTable,
  // CTableBody,
  // CTableDataCell,
  // CTableHead,
  // CTableHeaderCell,
  // CTableRow,
  // CWidgetStatsA,
  // CForm,
  // CFormLabel,
  // CFormInput,
  // CFormText,
  // CFormCheck,
  // CModal,
  // CModalHeader,
  // CModalBody,
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
// import usersTableData from "layouts/tables/data/usersTableData";
import MyForm from "./form/index"
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import { FormControl, FormLabel } from '@mui/material';
import { useState, useEffect } from "react";
import { UsersService } from "services/UsersAuth";
import toast, { Toaster } from 'react-hot-toast';





function Tables() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  // const { columns: urCols, rows: urRows } = usersTableData;
  const [settings, setSettings] = useState({
    sitename: "",
    email: "",
    optionalEmail: "",
    countryCode: "",
    mobileNo: "",
    optionalMobileNo: "",
    shortDesc: "",
    officeAddress: "",
    facebook: "",
    twitter: "",
    youtube: "",
    linkdin: "",
    instagram: "",
    pinterest: "",
    recruiter_amount: ""
  })
  const [settingData, setSettingData] = useState();


  useEffect(() => {
    getSettingDetail()
  }, [])

  useEffect(() => {


    setSettings({
      sitename: settingData?.sitename,
      email: settingData?.email,
      optionalEmail: settingData?.optionalemail,
      countryCode: settingData?.countrycode,
      mobileNo: settingData?.mobileno,
      optionalMobileNo: settingData?.optionalmobileno,
      shortDesc: settingData?.shortdesc,
      officeAddress: settingData?.officeaddress,
      facebook: settingData?.facebook,
      twitter: settingData?.twitter,
      youtube: settingData?.youtube,
      linkdin: settingData?.linkdin,
      instagram: settingData?.instagram,
      pinterest: settingData?.pinterest,
      recruiter_amount: settingData?.recruiter_amount
    })
  }, [settingData])



  const getSettingDetail = async () => {
    const adminList = await UsersService.getSettings();
    setSettingData(adminList?.data?.data)
  }


  const postSettingDetail = async (e) => {
    e.preventDefault()
    let Apidata = {
      id: settingData?.id,
      sitename: settings?.sitename ? settings?.sitename : null,
      email: settings?.email ? settings?.email : null,
      optionalEmail: settings?.optionalEmail ? settings?.optionalEmail : null,
      countryCode: settings?.countryCode ? settings?.countryCode : null,
      mobileNo: settings?.mobileNo ? settings?.mobileNo : null,
      optionalMobileNo: settings?.optionalMobileNo ? settings?.optionalMobileNo : null,
      shortDesc: settings?.shortDesc ? settings?.shortDesc : null,
      officeAddress: settings?.officeAddress ? settings?.officeAddress : null,
      facebook: settings?.facebook ? settings?.facebook : null,
      twitter: settings?.twitter ? settings?.twitter : null,
      youtube: settings?.youtube ? settings?.youtube : null,
      linkdin: settings?.linkdin ? settings?.linkdin : null,
      instagram: settings?.instagram ? settings?.instagram : null,
      pinterest: settings?.pinterest ? settings?.pinterest : null,
      recruiter_amount: settings?.recruiter_amount ? settings?.recruiter_amount : null,
    }
    const adminList = await UsersService.postSettings(Apidata);
    getSettingDetail()
    toast.success('Successfully Created!', {
      position: "top-right"
    })
  }





  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">

            <CCardBody>
              <form>
                <FormLabel>Website Link</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      sitename: e.target.value
                    }));
                  }}
                  value={settings.sitename}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                {/* <FormLabel>Logo</FormLabel>
                <TextField
                onChange={(e) => { setSettings({ sitename: e.target.value }) }}
                value={settings.sitename}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                /> */}
                {/* <FormLabel>Favicon</FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="normal"
                /> */}
                <FormLabel>Email</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      email: e.target.value
                    }));
                  }}
                  value={settings.email}
                  variant="outlined"
                  fontSize="10px"
                  fullWidth={true}
                  margin="normal"
                />
                <FormLabel>Another Email (optional)</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      optionalEmail: e.target.value
                    }));
                  }}
                  value={settings.optionalEmail}
                  defaultValue={settings.optionalEmail}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <FormLabel>countryCode</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      countryCode: e.target.value
                    }));
                  }}
                  value={settings.countryCode}
                  defaultValue={settings.countryCode}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <FormLabel>Phone Number</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      mobileNo: e.target.value
                    }));
                  }}
                  value={settings.mobileNo}
                  defaultValue={settings.mobileNo}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <FormLabel>Alternate Phone Number</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      optionalMobileNo: e.target.value
                    }));
                  }}
                  value={settings.optionalMobileNo}
                  defaultValue={settings.optionalMobileNo}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <FormLabel>Short Description</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      shortDesc: e.target.value
                    }));
                  }}
                  value={settings.shortDesc}
                  defaultValue={settings.shortDesc}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <FormLabel>Office Address</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      officeAddress: e.target.value
                    }));
                  }}
                  defaultValue={settings.officeAddress}
                  value={settings.officeAddress}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <FormLabel>YouTube Link</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      youtube: e.target.value
                    }));
                  }}
                  value={settings.youtube}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <FormLabel>Twitter Link</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      twitter: e.target.value
                    }));
                  }}
                  value={settings.twitter}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <FormLabel>Instagram Link</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      instagram: e.target.value
                    }));
                  }}
                  value={settings.instagram}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <FormLabel>pinterest Link</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      pinterest: e.target.value
                    }));
                  }}
                  value={settings.pinterest}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <FormLabel>facebook Link</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      facebook: e.target.value
                    }));
                  }}
                  value={settings.facebook}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />

                <FormLabel>Recruiters Default Fee ($)</FormLabel>
                <TextField
                  className="inside_input"
                  onChange={(e) => {
                    setSettings((prevSettings) => ({
                      ...prevSettings,
                      recruiter_amount: e.target.value
                    }));
                  }}
                  defaultValue={settings.recruiter_amount}
                  type="number"
                  value={settings.recruiter_amount}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={(e) => { postSettingDetail(e) }}
                >
                  Submit
                </Button>
              </form>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {/* <FormControl>
        <FormLabel>Enter Name</FormLabel>
        <TextField fullWidth></TextField>
        <Button>Submit</Button>
      </FormControl> */}

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;


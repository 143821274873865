import React, { useState, useEffect } from 'react'
import { UsersService } from 'services/UsersAuth';
import SoftBox from "components/SoftBox";
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import {
      CAvatar,
      CButton,
      CCard,
      CCardBody,
      CCardSubtitle,
      CCardTitle,
      CCol,
      CDropdown,
      CDropdownItem,
      CDropdownMenu,
      CDropdownToggle,
      CProgress,
      CRow,
      CCardHeader,
      CTable,
      CTableBody,
      CTableDataCell,
      CTableHead,
      CTableHeaderCell,
      CTableRow,
      CWidgetStatsA,
      CForm,
      CFormLabel,
      CFormInput,
      CFormText,
      CFormCheck,
      CModal,
      CModalHeader,
      CModalBody,
      CModalFooter,
      CModalTitle,
} from '@coreui/react-pro'
import { RiIndeterminateCircleLine } from "react-icons/ri";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import toast, { Toaster } from 'react-hot-toast';
import RichEditor from './Editor';
import { useNavigate } from 'react-router-dom';
import ViewTrainingMCQ from 'layouts/trainingProgramMCQ/ViewTrainingMCQ';
import AdminTableData from './Data/AdminTableData';
import EditRichEditor from './Data/NewEditEditor';




const ViewTrainingClasses = ({ setHide, coarseId, coarseName }) => {
      const navigate = useNavigate();
      const [TrainingClasses, setTrainingClasses] = useState();
      const [youtubeLink, setYoutubeLink] = useState([]);
      const [visible, setVisible] = useState(false);
      const [numOfVideo, setNumOfVideo] = useState([1]);
      const [numOfYtVideo, setNumOfYtVideo] = useState([1]);
      const [loader, setLoader] = useState(false);
      const [deleteID, setDeleteID] = useState();
      const [videoArr, setVideoArr] = useState([]);
      const [videoArrYT, setVideoArrYT] = useState([]);
      const [classID, setClassID] = useState();
      const [notes, setNotes] = useState("");
      const [ClassToDelete, setClassToDelete] = useState();
      const [ClassDetailToShow, setClassDetailToShow] = useState();
      const [VisibleEditModal, setVisibleEditModal] = useState(false);
      const [errors, setErrors] = useState({});
      const [classFeilds, setClassFeilds] = useState({
            title: "",
            course_notes: "<p>hello</p>",
            file_type: "",
            course_file: videoArr,
            duration: "",
            youtube_video_link: []
      });
      const [classFeildsEdit, setClassFeildsEdit] = useState({
            title: "",
            course_notes: "<p>hello</p>",
            file_type: "",
            course_file: videoArr,
            duration: "",
            youtube_video_link: []
      });

      const [videoArrReadOnly, setVideoArrReadOnly] = useState([]);
      const [numOfVideoReadOnly, setNumOfVideoReadOnly] = useState([]);

      const [videoArrYTReadOnly, setVideoArrYTReadOnly] = useState([]);
      const [numOfYtVideoReadOnly, setNumOfYtVideoReadOnly] = useState([]);

      const getTrainingCoarsesClass = async () => {
            let apiData = { course_id: coarseId }
            const resp = await UsersService.viewCourseClasses(apiData);
            setTrainingClasses(resp?.data?.data)
      }

      const goBack = () => {
            setHide(false)
      }
      const handleSave = async () => {

            var bodyFormData = new FormData();
            const formErrors = validateForm();
            bodyFormData.append('youtube_link[]', JSON.stringify(videoArrYT));
            videoArr.forEach((item, index) => {
                  bodyFormData.append(`videotitle[${index}]`, item.title);
                  bodyFormData.append(`class_file`, item.course_file);
            });

            bodyFormData.append('title', classFeilds.title);
            bodyFormData.append('course_id', coarseId);
            bodyFormData.append('course_notes', notes);
            bodyFormData.append('duration', classFeilds.duration);


            if (Object.keys(formErrors).length === 0) {
                  try {
                        setLoader(true)
                        const addInterviewGuide = await UsersService.saveCourseClasses(bodyFormData);
                        // You can handle the success scenario here if needed
                        setLoader(false)
                        getTrainingCoarsesClass()
                        toast.success('Successfully Created!', {
                              position: "top-right"
                        })
                  } catch (error) {
                        console.error("Error saving course classes:", error);
                        setLoader(false)
                        toast.error('Something went wrong!', {
                              position: "top-right"
                        })
                        // You can handle the error scenario here if needed
                  }
            } else {
                  // Set errors and prevent form submission
                  setErrors(formErrors);
            }

      }


      const handleEditSave = async () => {
            var bodyFormData = new FormData();
            bodyFormData.append('id', ClassDetailToShow?.id);
            videoArrYT?.length > 0 ? bodyFormData.append('youtube_link[]', JSON.stringify([...videoArrYTReadOnly, ...videoArrYT])) : bodyFormData.append('youtube_link[]', JSON.stringify(videoArrYTReadOnly));
            videoArr?.length > 0 && videoArr?.forEach((item, index) => {
                  bodyFormData.append(`videotitle[${index}]`, item.title);
                  bodyFormData.append(`class_file`, item.course_file);
            });

            bodyFormData.append('title', classFeildsEdit.title);
            bodyFormData.append('course_id', coarseId);
            bodyFormData.append('course_notes', classFeildsEdit?.course_notes);
            bodyFormData.append('duration', classFeildsEdit.duration);

            try {
                  // setLoader(true)
                  const addInterviewGuide = await UsersService.updateCourseClasses(bodyFormData);
                  // You can handle the success scenario here if needed
                  setLoader(false)
                  setVisibleEditModal(false)
                  getTrainingCoarsesClass()
                  toast.success('Successfully Created!', {
                        position: "top-right"
                  })
                  window.location.reload()
            } catch (error) {
                  console.error("Error saving course classes:", error);
                  // setLoader(false)
                  toast.error('Something went wrong!', {
                        position: "top-right"
                  })
                  // You can handle the error scenario here if needed
            }

      }

      const handleRemove = (index) => {
            setYoutubeLink((prevyoutubeLink) => {
                  const newyoutubeLink = [...prevyoutubeLink];
                  newyoutubeLink.splice(index, 1);
                  return newyoutubeLink;
            });
      };

      const handleRemoveVedio = (index) => {
            setVideoArr((prevyoutubeLink) => {
                  const newyoutubeLink = [...prevyoutubeLink];
                  newyoutubeLink.splice(index, 1);
                  return newyoutubeLink;
            });
            setNumOfVideo((prevyoutubeLink) => {
                  const newyoutubeLink = [...prevyoutubeLink];
                  newyoutubeLink.splice(index, 1);
                  return newyoutubeLink;
            });
      };

      const handleRemoveVedioYT = (index) => {
            setVideoArrYT((prevyoutubeLink) => {
                  const newyoutubeLink = [...prevyoutubeLink];
                  newyoutubeLink.splice(index, 1);
                  return newyoutubeLink;
            });
            setNumOfYtVideo((prevyoutubeLink) => {
                  const newyoutubeLink = [...prevyoutubeLink];
                  newyoutubeLink.splice(index, 1);
                  return newyoutubeLink;
            });
      };

      const handleRemoveClass = async (id) => {
            const removeclass = await UsersService.deleteClass(id)

            if (removeclass.status == 200) {
                  getTrainingCoarsesClass()
                  setVisible(false)
            }
      };

      const handleInputChange = (index, field, value) => {
            setVideoArr((prevFields) => {
                  const updatedFields = [...prevFields];
                  updatedFields[index] = {
                        ...updatedFields[index],
                        [field]: value,
                  };
                  return updatedFields;
            });
      };

      const handleInputChangeYT = (index, field, value) => {
            setVideoArrYT((prevFields) => {
                  const updatedFields = [...prevFields];
                  updatedFields[index] = {
                        ...updatedFields[index],
                        [field]: value,
                  };
                  return updatedFields;
            });
      };

      const onenNavigationPage = (classid) => {
            navigate('/add-classes-mcq', { state: { coarse: coarseId, class: classid } });
      };

      // for commit
      const onTextChange = (value, editor) => {
            setNotes(value);
      }
      const onTextChangeEdit = (value, editor) => {
            setClassFeildsEdit((prevSettings) => ({
                  ...prevSettings,
                  course_notes: value
            }));
      }

      useEffect(() => {
            getTrainingCoarsesClass()
      }, [])



      const validateForm = () => {
            let formErrors = {};
            if (!classFeilds.title) {
                  formErrors.title = "title is required";
            }


            if (!videoArr && !videoArrYT) {
                  formErrors.course_file = "Atleast one Video is required";
            }
            if (!classFeilds.duration) {
                  formErrors.duration = "duration is required";
            }


            return formErrors;
      };

      const columns = [
            { key: 'sequence', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'title', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'id', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'course_id', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'duration', _style: { fontSize: '16px' }, filter: true, sorter: false, },

            { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
      ]

      const changeStatus = async (id, status) => {
            // await UsersService.ActivateDactivateUser(id, status)
            // await getUsersDetail()
      }

      const deleteAdminConfirmation = (id) => {
            setClassToDelete(id)
            setVisible(true)
      }

      const showEditDetail = (data) => {
            setClassDetailToShow(data)
            setVisibleEditModal(true)
            setClassFeildsEdit({
                  title: data.title,
                  course_notes: data.course_notes,
                  file_type: data.file_type,
                  course_file: "",
                  duration: data.duration,
                  youtube_video_link: []
            })


            if (data?.coursevedioslist?.length > 0) {
                  const videoList = data?.coursevedioslist?.length > 0 && data?.coursevedioslist[0]?.vedio_file?.map(item => JSON.parse(item));
                  const ytList = data?.coursevedioslist?.length > 0 && data?.coursevedioslist[0].youtube_link && JSON.parse(data?.coursevedioslist[0].youtube_link[0]);
                  console.log("coursevedioslist_______item", data?.coursevedioslist[0])
                  setVideoArrReadOnly(videoList);
                  setVideoArrYTReadOnly(ytList ? ytList : []);
            }

            const initialVideoArr = data.coursevedioslist.map(video => {
                  // Parse the first (or only) item in vedio_file if it exists
                  const file = (video?.vedio_file?.length > 0 && video?.vedio_file[0]) ? JSON.parse(video.vedio_file[0]) : {};
                  return {
                        title: file.title || '',
                        course_file: file.url || ''
                  };
            });

            // setVideoArrReadOnly(initialVideoArr);
            // setNumOfVideoReadOnly(initialVideoArr.map((_, index) => index));


            const initialYtArr = data.coursevedioslist.flatMap(video =>
                  video?.youtube_link?.map(link => {
                        const ytData = JSON.parse(link);  // Parse each YouTube link JSON string
                        return {
                              yt_title: ytData.yt_title || '',
                              yt_link: ytData.yt_link || ''
                        };
                  })
            );
            // console.log("initialYtArr", initialYtArr);

            // setVideoArrYTReadOnly(initialYtArr);
            // setNumOfYtVideoReadOnly(initialYtArr.map((_, index) => index));

      }

      const getEmbedUrl = (link) => {
            const url = new URL(link);
            const videoId = url.pathname.split('/').pop(); // Extract video ID from URL
            return `https://www.youtube.com/embed/${videoId}`;
      };


      return (
            <>
                  <CButton className='float-end' onClick={() => { goBack() }}>Back</CButton>

                  <br />
                  {/* form  */}
                  <Toaster />
                  <SoftBox py={3}>
                        {loader ? <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                              </div>
                        </div> :
                              <SoftBox mb={3}>
                                    <CCardHeader>
                                          <strong> Add Class for {coarseName} Course</strong>
                                    </CCardHeader>
                                    <CCardBody>
                                          <SoftBox pt={2} pb={3} px={3}>
                                                <SoftBox component="form" role="form">
                                                      <SoftBox mb={2}>
                                                            <SoftTypography>Class Title</SoftTypography>
                                                            <SoftInput required placeholder="Course Title" onChange={(e) => {
                                                                  setClassFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        title: e.target.value
                                                                  }));
                                                            }}
                                                                  value={classFeilds?.title} />
                                                            {errors.title && (
                                                                  <SoftTypography color="error">
                                                                        {errors.title}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox>

                                                      <SoftBox mb={2}>
                                                            <SoftTypography>Class Notes</SoftTypography>
                                                            <RichEditor
                                                                  editorDefaultText={notes}
                                                                  handleUpdate={onTextChange}
                                                            ></RichEditor>
                                                            {/* <textarea name="postContent" rows={15} cols={95} onChange={(e) => {
                                                                  setClassFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        course_notes: e.target.value
                                                                  }));
                                                            }}
                                                                  value={classFeilds?.course_notes}
                                                            /> */}
                                                      </SoftBox>

                                                      {
                                                            numOfVideo?.map((item, index) => {
                                                                  return (
                                                                        <SoftBox mb={2} key={index}>
                                                                              <SoftTypography>video title </SoftTypography>
                                                                              <SoftInput required placeholder="Course Title" onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                                                                                    value={videoArr[index]?.title || ''} />
                                                                              <SoftTypography>video</SoftTypography>
                                                                              <input name="myInput" multiple type='file' onChange={(e) => handleInputChange(index, 'course_file', e.target.files[0])}
                                                                              />
                                                                              {numOfVideo.length - 1 == index && <a class="btn btn-primary float-end" onClick={() => { setNumOfVideo([...numOfVideo, numOfVideo[index] + 1]) }}>Add More</a>}
                                                                              {<a class="btn bg-danger float-end" onClick={() => { handleRemoveVedio(index); }}>Remove</a>}

                                                                        </SoftBox>
                                                                  );
                                                            })
                                                      }

                                                      {
                                                            numOfYtVideo?.map((item, index) => {
                                                                  return (
                                                                        <SoftBox mb={2} key={index}>
                                                                              <SoftTypography>youtube video title </SoftTypography>
                                                                              <SoftInput required placeholder="Course Title" onChange={(e) => handleInputChangeYT(index, 'yt_title', e.target.value)}
                                                                                    value={videoArrYT[index]?.yt_title || ''} />
                                                                              <SoftTypography>Youtube video Link</SoftTypography>
                                                                              <SoftInput required placeholder="Course Link" onChange={(e) => handleInputChangeYT(index, 'yt_link', e.target.value)}
                                                                                    value={videoArrYT[index]?.yt_link || ''} />
                                                                              {numOfYtVideo.length - 1 == index && <a class="btn btn-primary float-end" onClick={() => { setNumOfYtVideo([...numOfYtVideo, numOfYtVideo[index] + 1]) }}>Add More</a>}
                                                                              {<a class="btn bg-danger float-end" onClick={() => { handleRemoveVedioYT(index); }}>Remove</a>}
                                                                        </SoftBox>
                                                                  );
                                                            })
                                                      }



                                                      {/* <SoftBox mb={2} >
                                                            <SoftTypography>youtube video link</SoftTypography>
                                                            <SoftInput placeholder="Write your domain and press enter" onChange={(e) => {
                                                                  setClassFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        youtube_video_link: e.target.value
                                                                  }));
                                                            }}
                                                                  onKeyDown={(e) => {
                                                                        if (e.key === "Enter" && e.target.value) {
                                                                              setYoutubeLink([...youtubeLink, e.target.value]);
                                                                              setClassFeilds((prevSettings) => ({
                                                                                    ...prevSettings,
                                                                                    youtube_video_link: ""
                                                                              }));
                                                                        }
                                                                  }}
                                                                  value={classFeilds.youtube_video_link} />
                                                      </SoftBox> */}
                                                      {
                                                            youtubeLink?.map((item, index) => {
                                                                  return (
                                                                        <SoftTypography key={index}>youtubeLink {index + 1} - {item}   <RiIndeterminateCircleLine onClick={() => { handleRemove(index) }} />
                                                                        </SoftTypography>
                                                                  )
                                                            })
                                                      }

                                                      <SoftBox mb={2}>
                                                            <SoftTypography>Total duration</SoftTypography>
                                                            <SoftInput placeholder="Total duration" onChange={(e) => {
                                                                  setClassFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        duration: e.target.value
                                                                  }));
                                                            }}
                                                                  value={classFeilds?.duration} />
                                                            {errors.duration && (
                                                                  <SoftTypography color="error">
                                                                        {errors.duration}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox>

                                                      {/* <ViewTrainingMCQ setHide={setHide} coarseId={CoarseID} classId={ClassID} /> */}

                                                      <SoftBox mb={2}>
                                                            <CButton onClick={() => { handleSave() }}>Create</CButton>
                                                      </SoftBox>
                                                </SoftBox>
                                          </SoftBox>
                                    </CCardBody>
                              </SoftBox>}
                  </SoftBox >

                  {/* classes  */}
                  < Box sx={{ width: '100%' }}>
                        <SoftBox mb={3}>
                              <CRow>
                                    <CCol xs={12}>
                                          <CCard className="mb-4">
                                                <CCardBody>
                                                      <AdminTableData columns={columns} data={TrainingClasses} changeStatus={changeStatus} deleteAdminConfirmation={deleteAdminConfirmation} showDetail={showEditDetail} />
                                                </CCardBody>
                                          </CCard>
                                    </CCol>
                              </CRow>
                        </SoftBox>
                  </Box >

                  {/* modal  */}
                  <CModal
                        visible={visible}
                        onClose={() => setVisible(false)}
                        aria-labelledby="LiveDemoExampleLabel"
                        alignment="center"
                  >
                        <CModalHeader>
                              <CModalTitle id="LiveDemoExampleLabel">Delete Class</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                              <p>Are you sure you want to delete!</p>
                        </CModalBody>
                        <CModalFooter>
                              <CButton color="secondary" onClick={() => setVisible(false)}>
                                    No
                              </CButton>
                              <CButton color="primary" onClick={() => { handleRemoveClass(ClassToDelete) }}>Yes</CButton>
                        </CModalFooter>
                  </CModal>













                  {/* edit class modal  */}


                  <CModal
                        visible={VisibleEditModal}
                        onClose={() => setVisibleEditModal(false)}
                        aria-labelledby="LiveDemoExampleLabel"
                        alignment="center"
                        scrollable
                        size='lg'
                  >
                        <CModalHeader mt={5}>
                              <CModalTitle id="LiveDemoExampleLabel">Edit Class</CModalTitle>
                        </CModalHeader>
                        <CModalBody>

                              <CCardHeader>
                                    <strong> Edit Class </strong>
                              </CCardHeader>
                              <SoftBox pt={2} pb={3} px={3}>
                                    <SoftBox component="form" role="form">
                                          <SoftBox mb={2}>
                                                <SoftTypography>Class Title</SoftTypography>
                                                <SoftInput required placeholder="Course Title" onChange={(e) => {
                                                      setClassFeildsEdit((prevSettings) => ({
                                                            ...prevSettings,
                                                            title: e.target.value
                                                      }));
                                                }}
                                                      value={classFeildsEdit?.title} />

                                          </SoftBox>

                                          <SoftBox mb={2}>
                                                <SoftTypography>Class Notes</SoftTypography>
                                                <EditRichEditor
                                                      editorDefaultText={classFeildsEdit?.course_notes}
                                                      handleUpdate={onTextChangeEdit}
                                                ></EditRichEditor>

                                          </SoftBox>

                                          {/* test  */}

                                          {videoArrReadOnly?.map((item, index) => (
                                                <SoftBox mb={2} key={index}>

                                                      <SoftTypography>Video Title</SoftTypography>
                                                      <SoftInput
                                                            required
                                                            placeholder="Video Title"
                                                            value={item.title || ''}
                                                      />
                                                      <SoftTypography>Video File URL</SoftTypography>
                                                      <input
                                                            type="text"
                                                            readOnly
                                                            value={item.url || ''}
                                                      />
                                                </SoftBox>
                                          ))}

                                          {/* test  */}

                                          {
                                                numOfVideo?.map((item, index) => {
                                                      return (
                                                            <SoftBox mb={2} key={index}>
                                                                  <SoftTypography>video title </SoftTypography>
                                                                  <SoftInput required placeholder="Course Title" onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                                                                        value={videoArr[index]?.title || ''} />
                                                                  <SoftTypography>video</SoftTypography>
                                                                  <input name="myInput" multiple type='file' onChange={(e) => handleInputChange(index, 'course_file', e.target.files[0])}
                                                                  />
                                                                  {numOfVideo.length - 1 == index && <a class="btn btn-primary float-end" onClick={() => { setNumOfVideo([...numOfVideo, numOfVideo[index] + 1]) }}>Add More</a>}
                                                                  {<a class="btn bg-danger float-end" onClick={() => { handleRemoveVedio(index); }}>Remove</a>}

                                                            </SoftBox>
                                                      );
                                                })
                                          }


                                          {/* youtube test  */}

                                          {videoArrYTReadOnly?.map((item, index) => (
                                                <SoftBox mb={2} key={index}>
                                                      <SoftTypography>YouTube Video Title</SoftTypography>
                                                      <SoftInput required placeholder="YouTube Title"
                                                            value={videoArrYTReadOnly[index]?.yt_title || ''}
                                                            onChange={(e) => {
                                                                  const updatedVideos = [...videoArrYTReadOnly];
                                                                  updatedVideos[index] = { ...updatedVideos[index], yt_title: e.target.value };
                                                                  setVideoArrYTReadOnly(updatedVideos); // Assuming you have a setState function for this array
                                                            }}
                                                      />
                                                      <SoftTypography>YouTube Link</SoftTypography>
                                                      <SoftInput required placeholder="YouTube Link"
                                                            value={videoArrYTReadOnly[index]?.yt_link || ''}
                                                            onChange={(e) => {
                                                                  const updatedVideos = [...videoArrYTReadOnly];
                                                                  updatedVideos[index] = { ...updatedVideos[index], yt_link: e.target.value };
                                                                  setVideoArrYTReadOnly(updatedVideos); // Assuming you have a setState function for this array
                                                            }}
                                                      />
                                                </SoftBox>
                                          ))}




                                          {/* youtube test  */}

                                          {
                                                numOfYtVideo?.map((item, index) => {
                                                      return (
                                                            <SoftBox mb={2} key={index}>
                                                                  <SoftTypography>youtube video title </SoftTypography>
                                                                  <SoftInput required placeholder="Course Title" onChange={(e) => handleInputChangeYT(index, 'yt_title', e.target.value)}
                                                                        value={videoArrYT[index]?.yt_title || ''} />
                                                                  <SoftTypography>Youtube video Link</SoftTypography>
                                                                  <SoftInput required placeholder="Course Link" onChange={(e) => handleInputChangeYT(index, 'yt_link', e.target.value)}
                                                                        value={videoArrYT[index]?.yt_link || ''} />
                                                                  {numOfYtVideo.length - 1 == index && <a class="btn btn-primary float-end" onClick={() => { setNumOfYtVideo([...numOfYtVideo, numOfYtVideo[index] + 1]) }}>Add More</a>}
                                                                  {<a class="btn bg-danger float-end" onClick={() => { handleRemoveVedioYT(index); }}>Remove</a>}
                                                            </SoftBox>
                                                      );
                                                })
                                          }



                                          {
                                                youtubeLink?.map((item, index) => {
                                                      return (
                                                            <SoftTypography key={index}>youtubeLink {index + 1} - {item}   <RiIndeterminateCircleLine onClick={() => { handleRemove(index) }} />
                                                            </SoftTypography>
                                                      )
                                                })
                                          }

                                          <SoftBox mb={2}>
                                                <SoftTypography>Total duration</SoftTypography>
                                                <SoftInput placeholder="Total duration" onChange={(e) => {
                                                      setClassFeildsEdit((prevSettings) => ({
                                                            ...prevSettings,
                                                            duration: e.target.value
                                                      }));
                                                }}
                                                      value={classFeildsEdit?.duration} />

                                          </SoftBox>

                                          {/* <ViewTrainingMCQ setHide={setHide} coarseId={CoarseID} classId={ClassID} /> */}

                                          <SoftBox mb={2}>
                                                <CButton onClick={() => { handleEditSave() }}>Save Edits</CButton>
                                          </SoftBox>
                                    </SoftBox>
                              </SoftBox>

                        </CModalBody>
                        <CModalFooter>
                              <CButton color="secondary" onClick={() => setVisibleEditModal(false)}>
                                    Close
                              </CButton>

                        </CModalFooter>
                  </CModal>
            </>
      )
}

export default ViewTrainingClasses
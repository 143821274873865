import React, { useState, useEffect } from 'react'
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftPieChart from 'components/SoftPieChart'
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import { UsersService } from 'services/UsersAuth';
import { Margin } from '@mui/icons-material';


const StudentGraph = () => {
      const [graphData, setGraphData] = useState();

      useEffect(() => {
            getUsersDetail()
      }, [])

      const getUsersDetail = async () => {
            let ApiData = {
                  user_type: "student",
                  // year: year
            }
            const GraphData = await UsersService?.getDashboardGraphData(ApiData);
            setGraphData(GraphData?.data?.data)
      }

      const gradientLineChartData = {
            labels: graphData?.monthsName,
            datasets: [
                  {
                        label: "mentorCounts",
                        color: "info",
                        data: graphData?.mentorCounts,
                  },
                  {
                        label: "recruiterCounts",
                        color: "dark",
                        data: graphData?.recruiterCounts,
                  },
                  {
                        label: "studentCounts",
                        color: "warning",
                        data: graphData?.studentCounts
                        ,
                  },
            ],
      };
      return (
            <SoftBox mb={3} >

                  <Grid container spacing={6} style={{ Margin: "auto" }}>
                        <Grid item xs={12} lg={6}>
                              {/* <SoftPieChart /> */}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                              <GradientLineChart
                                    title="Students | Mentors | Recruiters"
                                    description={
                                          <SoftBox display="flex" alignItems="center">
                                          </SoftBox>
                                    }
                                    height="20.25rem"
                                    chart={gradientLineChartData && gradientLineChartData}
                              />
                        </Grid>
                  </Grid>
            </SoftBox>
      )
}

export default StudentGraph
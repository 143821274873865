/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Graph1 from "./components/Graph/Graph1/index"
import Graph2 from "./components/Graph/Graph2/index"

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import { UsersService } from "services/UsersAuth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SoftPieChart from 'components/SoftPieChart'
import StudentGraph from "./components/StudentGraph/StudentGraph";
import RecruiterGraph from "./components/RecruiterGraph/RecriuterGraph";
import TrainerGraph from "./components/TrainerGraph/TrainerGraph";
import LinkedInLogin from "services/sociallogin/LinkedInLogin";

function Dashboard() {
  const navigate = useNavigate()
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const [mentors, setMentors] = useState();
  const [recruiters, setRecruiters] = useState();
  const [students, setStudents] = useState();
  const [admins, setAdmins] = useState();
  const [graphData, setGraphData] = useState();
  const [switchTab, setSwitchTab] = useState(1);


  useEffect(() => {
    getUsersDetail()
    const localStorageData = JSON.parse(localStorage.getItem("user_data"))
    if (!localStorageData) {
      navigate('/authentication/sign-in')
    }

  }, [])




  const getUsersDetail = async () => {
    const studentList = await UsersService?.UserList(2);
    const recruiterList = await UsersService?.UserList(3);
    const mentorList = await UsersService?.UserList(4);
    const adminList = await UsersService?.AdminList();
    const GraphData = await UsersService?.getDashboardGraphData();
    setStudents(studentList?.data?.data)
    setRecruiters(recruiterList?.data?.data)
    setMentors(mentorList?.data?.data)
    setAdmins(adminList?.data?.data)

    setGraphData(GraphData?.data?.data)
  }

  const gradientLineChartData = {
    labels: graphData?.monthsName,
    datasets: [
      {
        label: "mentorCounts",
        color: "info",
        data: graphData?.mentorCounts,
      },
      {
        label: "recruiterCounts",
        color: "dark",
        data: graphData?.recruiterCounts,
      },
      {
        label: "studentCounts",
        color: "warning",
        data: graphData?.studentCounts
        ,
      },
    ],
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <LinkedInLogin /> */}
      {/* <SoftBox>
        <Graph1 />
        <Graph2 />
      </SoftBox> */}
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3} onClick={() => { setSwitchTab(1) }}>
              <MiniStatisticsCard
                title={{ text: "total students" }}
                count={students?.length}
                // percentage={{ color: "success", text: "+55%" }}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3} onClick={() => { setSwitchTab(2) }}>
              <MiniStatisticsCard
                title={{ text: "total mentors" }}
                count={mentors?.length}
                // percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3} onClick={() => { setSwitchTab(3) }}>
              <MiniStatisticsCard
                title={{ text: "total recruiters" }}
                count={recruiters?.length}
                // percentage={{ color: "error", text: "-2%" }}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3} onClick={() => { navigate('/admin') }}>
              <MiniStatisticsCard
                title={{ text: "total admin" }}
                count={admins?.length}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} sm={6} xl={3} >
              <MiniStatisticsCard
                title={{ text: "student request amount" }}
                count={graphData?.student_request_amount ? graphData?.student_request_amount : 0}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3} >
              <MiniStatisticsCard
                title={{ text: "Job amount" }}
                count={graphData?.job_amount ? graphData?.job_amount : 0}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        {/* <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SoftBox> */}
        {switchTab == 1 && <StudentGraph />}
        {switchTab == 2 && <TrainerGraph />}
        {switchTab == 3 && <RecruiterGraph />}
        {switchTab == 4 && <StudentGraph />}
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}
      </SoftBox>
      {/* <div style={{position: "absolute", bottom: "-500px"}}> */}
      <Footer />
      {/* </div> */}
    </DashboardLayout>
  );
}

export default Dashboard;

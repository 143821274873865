import React, { useEffect, useState } from 'react'
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftPieChart from 'components/SoftPieChart'
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import { UsersService } from 'services/UsersAuth';


const TrainerGraph = () => {
      const [graphData, setGraphData] = useState();
      const data = [
            {
                  id: 0, value: graphData?.mentorBookings?.completed_count
                  , label: 'Completed'
            },
            {
                  id: 1, value: graphData?.mentorBookings?.in_process_count
                  , label: 'In process'
            },
            {
                  id: 2, value: graphData?.mentorBookings?.
                        new_count
                  , label: 'New'
            },
      ];
      useEffect(() => {
            getUsersDetail()
      }, [])

      const getUsersDetail = async () => {
            let ApiData = {
                  user_type: "mentor",
                  // year: year
            }
            const GraphData = await UsersService?.getDashboardGraphData(ApiData);

            setGraphData(GraphData?.data?.data)
      }

      const gradientLineChartData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            datasets: [
                  {
                        label: "Revenue",
                        color: "info",
                        data: graphData?.mentorMonthlyRevenue,
                  }
            ],
      };
      return (
            <SoftBox mb={3}>

                  <Grid container spacing={3}>
                        <Grid item xs={12} lg={5}>
                              {data?.length > 0 && <SoftPieChart data={data && data} />}
                        </Grid>
                        <Grid item xs={12} lg={7}>
                              <GradientLineChart
                                    title="Mentors Monthly Revenue"
                                    description={
                                          <SoftBox display="flex" alignItems="center">
                                          </SoftBox>
                                    }
                                    height="20.25rem"
                                    chart={gradientLineChartData && gradientLineChartData}
                              />
                        </Grid>
                  </Grid>
            </SoftBox>
      )
}

export default TrainerGraph
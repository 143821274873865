import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import {
      CAvatar,
      CButton,
      CCard,
      CCardBody,
      CCardSubtitle,
      CCardTitle,
      CCol,
      CDropdown,
      CDropdownItem,
      CDropdownMenu,
      CDropdownToggle,
      CProgress,
      CRow,
      CCardHeader,
      CTable,
      CTableBody,
      CTableDataCell,
      CTableHead,
      CTableHeaderCell,
      CTableRow,
      CWidgetStatsA,
      CForm,
      CFormLabel,
      CFormInput,
      CFormText,
      CFormCheck,
      CModal,
      CModalHeader,
      CModalBody,
      CModalFooter,
      CModalTitle
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { utils, writeFileXLSX } from "xlsx";

// Data

import JobsData from "./Data/JobsData";
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { useEffect, useState } from "react";
import { UsersService } from "services/UsersAuth";

function CourseReview() {

      const [trainers, setTrainers] = useState();
      const [jobs, setJobs] = useState();
      const [visible, setVisible] = useState(false)
      const [jobToDelete, setJobToDelete] = useState()
      const [SelectedCourse, setSelectedCourse] = useState()
      const [VisibleStatus, setVisibleStatus] = useState()



      useEffect(() => {
            getUsersDetail()
      }, [])

      const getUsersDetail = async () => {
            const studentList = await UsersService?.getCoarseReview();
            setJobs(studentList?.data?.data)
      }

      const changeStatus = async (id, status) => {
            await UsersService.updateCourseStatus({ id: SelectedCourse?.id, status: SelectedCourse?.status })
            await getUsersDetail()
            setVisibleStatus(false)
      }

      const deleteCompanyConfirmation = (id) => {
            setJobToDelete(id)
            setVisible(true)
      }

      const deleteJobById = async () => {
            // const deleteCom = await UsersService.deleteJob(jobToDelete)
            await getUsersDetail()
            setVisible(false)

      }

      const changeStatusConfirmation = async (id, status) => {
            setSelectedCourse({ id, status })
            setVisibleStatus(true)
      }

      const columns = [
            // { key: 'id', _style: { width: '4%', fontSize: '16px' }, filter: true, sorter: true, },
            { key: 'status', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'course_id', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'feedback_title', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'rating', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'review', _style: { width: '18%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'email', label: "student email", _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'fname', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'start_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
            // { key: 'end_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
            { key: 'student_id', _style: { width: '25%', fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'status', _style: { width: '10%', fontSize: '16px' }, filter: false, sorter: false, },
            { key: 'lname', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'lname', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'title', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'payment_status', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'paymentid', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'salarypackage', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'skills', _style: { fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
      ]

      return (
            <>
                  <DashboardLayout>
                        <DashboardNavbar />


                        <SoftBox py={3}>

                              <CCardHeader>
                                    <strong>All Course Review</strong> <small></small>
                              </CCardHeader>
                              <CRow>
                                    <CCol xs={12}>
                                          <CCard className="mb-4">
                                                <CCardBody>
                                                      <JobsData columns={columns} data={jobs} changeStatusConfirmation={changeStatusConfirmation} changeStatus={changeStatus} deleteCompanyConfirmation={deleteCompanyConfirmation} />
                                                </CCardBody>
                                          </CCard>
                                    </CCol>
                              </CRow>
                        </SoftBox>
                        <Footer />
                  </DashboardLayout>
                  <CModal
                        visible={visible}
                        onClose={() => setVisible(false)}
                        aria-labelledby="LiveDemoExampleLabel"
                        alignment="center"
                  >
                        <CModalHeader>
                              <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                              <p>Are you sure you want to delete!</p>
                        </CModalBody>
                        <CModalFooter>
                              <CButton color="secondary" onClick={() => setVisible(false)}>
                                    No
                              </CButton>
                              <CButton color="primary" onClick={() => deleteJobById()}>Yes</CButton>
                        </CModalFooter>
                  </CModal>



                  <CModal
                        visible={VisibleStatus}
                        onClose={() => setVisibleStatus(false)}
                        aria-labelledby="LiveDemoExampleLabel"
                        alignment="center"
                  >
                        <CModalHeader>
                              <CModalTitle id="LiveDemoExampleLabel">{SelectedCourse?.status == 1 ? "Activate" : "Deactivate"} Review</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                              <p>Are you sure you want to {SelectedCourse?.status == 1 ? "active" : "deactive"} Review!</p>
                        </CModalBody>
                        <CModalFooter>
                              <CButton color="secondary" onClick={() => setVisibleStatus(false)}>
                                    No
                              </CButton>
                              <CButton color="primary" onClick={() => { changeStatus() }}>Yes</CButton>
                        </CModalFooter>
                  </CModal>
            </>
      );
}

export default CourseReview;


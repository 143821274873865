import React from 'react'
import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
      CitySelect,
      CountrySelect,
      StateSelect,
      LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { useNavigate } from 'react-router-dom';


// Authentication layout components
// import Socials from "layouts/authentication/components/Socials";
import Separator from "../../authentication/components/Separator";
import { CAlert } from '@coreui/react'
// Images
import curved6 from "../../../assets/images/curved-images/curved14.jpg";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { UsersService } from 'services/UsersAuth';
import SoftAlert from 'components/SoftAlert';
import toast, { Toaster } from 'react-hot-toast';

const CreateNewAdmin = () => {
      const [age, setAge] = React.useState('');
      const navigate = useNavigate()
      const [registerData, setRegisterData] = useState({
            role_id: "",
            fname: "",
            lname: "",
            email: "",
            password: "",
            countryCode: "",
            mobile: "",
            dob: "",
            citizen: "",
            country: "",
            state: "",
            city: "",
            tcCheck: ""
      })

      const [countryid, setCountryid] = useState(0);
      const [stateid, setstateid] = useState(0);
      const [cityid, setcityid] = useState(0);
      const [error, setError] = useState(false);
      const [agreement, setAgremment] = useState(true);
      const [errors, setErrors] = useState({});

      const handleChange = (event) => {
            setAge(event.target.value);
      };
      const handleSetAgremment = () => setAgremment(!agreement);
      useEffect(() => {
      }, [registerData])

      const createAdminApi = async () => {
            const formErrors = validateForm();
            setError(false)
            if (Object.keys(formErrors).length === 0) {
                  try {
                        const resp = await UsersService.createAdmin(registerData)

                        if (resp.status == 200) {
                              navigate('/admin')
                              setRegisterData({
                                    role_id: "",
                                    fname: "",
                                    lname: "",
                                    email: "",
                                    password: "",
                                    countryCode: "",
                                    mobile: "",
                                    dob: "",
                                    citizen: "",
                                    country: "",
                                    state: "",
                                    city: "",
                                    tcCheck: ""
                              })
                              toast.success('Successfully created!', {
                                    position: "top-right"
                              })
                        }
                  } catch (error) {
                        setError(true)
                        toast.error('Something went wrong!', {
                              position: "top-right"
                        })
                  }
            } else {
                  // Set errors and prevent form submission
                  setErrors(formErrors);


            }
      }

      const ValidatePassword = (password) => {
            const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&-]{11,}$/;
            console.log("regex.test(password)", regex?.test(password))
            return regex?.test(password);
      };

      const validateForm = () => {
            let formErrors = {};

            if (!registerData.fname) {
                  formErrors.fname = "First Name is required";
            }
            if (!registerData.lname) {
                  formErrors.lname = "Last Name is required";
            }
            if (!registerData.email) {
                  formErrors.email = "Email is required";
            }
            if (!registerData.password) {
                  formErrors.password = "Password  is required";
            }
            if (registerData.password) {
                  const checkData = ValidatePassword(registerData.password)
                  if (!checkData) {
                        formErrors.password = `Password must be at least 11 characters long,
                                contain at least one lowercase letter, one
                                uppercase letter, one digit, and one special
                                character (@$!%*?&).`;
                  }
            }
            // if (!registerData.mobile) {
            //       formErrors.mobile = "Mobile No. is required";
            // }
            if (!registerData.dob) {
                  formErrors.dob = "DOB is required";
            }
            if (!registerData.citizen) {
                  formErrors.citizen = "Citizen is required";
            }
            // if (!countryid) {
            //       formErrors.country = "Country is required";
            // }
            // if (!stateid) {
            //       formErrors.state = "State is required";
            // }
            // if (!cityid) {
            //       formErrors.city = "City is required";
            // }
            if (!agreement) {
                  formErrors.tcCheck = "Terms and Conditions should be selected";
            }

            return formErrors;
      };



      return (
            <>

                  <DashboardLayout>
                        <Toaster />
                        <CAlert color="danger" dismissible visible={error} onClose={() => setError(false)}>
                              Something went wrong!
                        </CAlert>
                        <Grid container justifyContent='center'>
                              <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                                    <Card>
                                          <SoftBox p={3} mb={1} textAlign="center">
                                                <SoftTypography variant="h5" fontWeight="medium">
                                                      Create New Admin
                                                </SoftTypography>
                                          </SoftBox>
                                          {/* <SoftBox mb={2}>
                                              <Socials />
                                            </SoftBox> */}
                                          {/* <Separator /> */}
                                          <SoftBox pt={2} pb={3} px={3}>
                                                <SoftBox component="form" role="form">
                                                      <SoftBox mb={2}>
                                                            <SoftInput placeholder="First Name" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        fname: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.fname} />
                                                            {errors.fname && (
                                                                  <SoftTypography color="error">
                                                                        {errors.fname}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox>
                                                      <SoftBox mb={2}>
                                                            <SoftInput placeholder="Last Name" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        lname: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.lname} />
                                                            {errors.lname && (
                                                                  <SoftTypography color="error">
                                                                        {errors.lname}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox>
                                                      <SoftBox mb={2}>
                                                            <SoftInput type="email" placeholder="email" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        email: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.email} />
                                                            {errors.email && (
                                                                  <SoftTypography color="error">
                                                                        {errors.email}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox>
                                                      <SoftBox mb={2}>
                                                            <SoftInput type="password" placeholder="Password" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        password: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.password} />
                                                            {errors.password && (
                                                                  <SoftTypography color="error">
                                                                        {errors.password}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox>
                                                      {/* <SoftBox mb={2}>
                                                            <SoftInput type="number" placeholder="countryCode" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        countryCode: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.countryCode} />
                                                            {errors.countryCode && (
                                                                  <SoftTypography color="error">
                                                                        {errors.countryCode}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox> */}
                                                      <SoftBox mb={2}>
                                                            <SoftInput type="number" placeholder="mobile" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        mobile: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.mobile} />
                                                            {errors.mobile && (
                                                                  <SoftTypography color="error">
                                                                        {errors.mobile}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox>
                                                      <SoftBox mb={2}>
                                                            <SoftInput type="date" placeholder="dob" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        dob: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.dob} />
                                                            {errors.dob && (
                                                                  <SoftTypography color="error">
                                                                        {errors.dob}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox>
                                                      <SoftBox mb={2}>
                                                            <SoftInput placeholder="citizen" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        citizen: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.citizen} />
                                                            {errors.citizen && (
                                                                  <SoftTypography color="error">
                                                                        {errors.citizen}
                                                                  </SoftTypography>
                                                            )}
                                                      </SoftBox>
                                                      <SoftBox mb={2}>
                                                            <FormControl>
                                                                  <FormLabel id="demo-row-radio-buttons-group-label">Role</FormLabel>
                                                                  <RadioGroup
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="row-radio-buttons-group"
                                                                        onChange={(e) => {
                                                                              setRegisterData((prevSettings) => ({
                                                                                    ...prevSettings,
                                                                                    role_id: e.target.value
                                                                              }));
                                                                        }}
                                                                  >
                                                                        <FormControlLabel value={2} control={<Radio />} label="Admin" />
                                                                        <FormControlLabel value={3} control={<Radio />} label="Support " />
                                                                        <FormControlLabel value={4} control={<Radio />} label="Training Course " />

                                                                  </RadioGroup>
                                                            </FormControl>
                                                      </SoftBox>

                                                      <div>
                                                            <h6>Country</h6>
                                                            <CountrySelect
                                                                  onChange={(e) => {
                                                                        setCountryid(e.name);
                                                                        setRegisterData((prevSettings) => ({
                                                                              ...prevSettings,
                                                                              country: e.name
                                                                        }));
                                                                  }}
                                                                  placeHolder="Select Country"
                                                            />
                                                            {countryid && (
                                                                  <SoftTypography color="error">
                                                                        {errors.countryid}
                                                                  </SoftTypography>
                                                            )}
                                                            {/* <h6>State</h6>
                                                            <StateSelect
                                                                  countryid={countryid}
                                                                  onChange={(e) => {
                                                                        setstateid(e.name);
                                                                        setRegisterData((prevSettings) => ({
                                                                              ...prevSettings,
                                                                              state: e.name
                                                                        }));
                                                                  }}
                                                                  placeHolder="Select State"
                                                            /> */}
                                                            {/* {stateid && (
                                                                  <SoftTypography color="error">
                                                                        {errors.stateid}
                                                                  </SoftTypography>
                                                            )}
                                                            <h6>City</h6>
                                                            <CitySelect
                                                                  countryid={countryid}
                                                                  stateid={stateid}
                                                                  onChange={(e) => {
                                                                        setcityid(e.name);
                                                                        setRegisterData((prevSettings) => ({
                                                                              ...prevSettings,
                                                                              city: e.name
                                                                        }));
                                                                  }}
                                                                  placeHolder="Select City"
                                                            />
                                                            {cityid && (
                                                                  <SoftTypography color="error">
                                                                        {errors.cityid}
                                                                  </SoftTypography>
                                                            )} */}

                                                      </div>



                                                      <SoftBox display="flex" alignItems="center">
                                                            <Checkbox checked={agreement} onChange={handleSetAgremment} />
                                                            <SoftTypography
                                                                  variant="button"
                                                                  fontWeight="regular"
                                                                  onClick={handleSetAgremment}
                                                                  sx={{ cursor: "poiner", userSelect: "none" }}
                                                            >
                                                                  &nbsp;&nbsp;I agree the&nbsp;
                                                            </SoftTypography>
                                                            <SoftTypography
                                                                  component="a"
                                                                  href="#"
                                                                  variant="button"
                                                                  fontWeight="bold"
                                                                  textGradient
                                                            >
                                                                  Terms and Conditions
                                                            </SoftTypography>
                                                      </SoftBox>
                                                      <SoftBox mt={4} mb={1}>
                                                            <SoftButton variant="gradient" color="dark" fullWidth onClick={() => { createAdminApi() }}>
                                                                  Create Admin
                                                            </SoftButton>
                                                      </SoftBox>
                                                </SoftBox>
                                          </SoftBox>
                                    </Card>

                              </Grid>
                        </Grid>
                  </DashboardLayout>

            </>
      )
}

export default CreateNewAdmin
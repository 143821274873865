import { useState, useRef } from "react";

export default function OtpInput({ length = 6, onChange }) {
      const [otp, setOtp] = useState(Array(length).fill(""));
      const inputRefs = useRef([]);

      const handleChange = (value, index) => {
            if (!/^\d*$/.test(value)) return; // Allow only numeric input
            const newOtp = [...otp];
            newOtp[index] = value.slice(-1); // Keep only the last digit
            setOtp(newOtp);
            onChange(newOtp.join("")); // Combine digits and pass to parent
            // Move to the next input field
            if (value && index < length - 1) {
                  inputRefs.current[index + 1].focus();
            }
      };

      const handleKeyDown = (e, index) => {
            if (e.key === "Backspace" && !otp[index] && index > 0) {
                  inputRefs.current[index - 1].focus();
            }
      };

      const handlePaste = (e) => {
            const pasteData = e.clipboardData.getData("text").slice(0, length);
            if (/^\d+$/.test(pasteData)) {
                  const newOtp = pasteData.split("").concat(Array(length).fill("")).slice(0, length);
                  setOtp(newOtp);
                  onChange(newOtp.join(""));
            }
      };

      return (
            <div style={{ display: "flex", gap: "10px" }}>
                  {otp.map((_, index) => (
                        <input
                              key={index}
                              type="text"
                              value={otp[index]}
                              onChange={(e) => handleChange(e.target.value, index)}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              onPaste={handlePaste}
                              ref={(el) => (inputRefs.current[index] = el)}
                              maxLength={1}
                              style={{
                                    height: "40px",
                                    width: "38px",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                              }}
                        />
                  ))}
            </div>
      );
}

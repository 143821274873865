import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Link } from 'react-router-dom';
import SoftBox from "components/SoftBox";
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button
} from '@material-ui/core';
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress,
  CRow,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CWidgetStatsA,
  CForm,
  CFormLabel,
  CFormInput,
  CFormText,
  CFormCheck,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
} from '@coreui/react-pro'
import { UsersService } from 'services/UsersAuth';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ViewTrainingClasses from './ViewTrainingClasses';
import { useNavigate } from 'react-router-dom';
import RichEditor from './Editor';
import toast, { Toaster } from 'react-hot-toast';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const coursesData = [
  { id: 1, name: 'Networking' },
  { id: 2, name: 'CyberSecurity' },
  { id: 3, name: 'Cloud Architect and Security' },
  { id: 4, name: 'Cloud DevOps' }
  // Add more courses as needed
];

const TrainingProgramClass = () => {
  const classes = useStyles();
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [CoarseID, setCoarseID] = useState();
  const [coarseName, setCoarseName] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [editedName, setEditedName] = useState({});
  const [TrainingCoarses, setTrainingCoarses] = useState();
  const [currentEditingCourse, setCurrentEditingCourse] = useState();
  const [visibleEditCourse, setVisibleEditCourse] = useState(false);
  const [TrainingClasses, setTrainingClasses] = useState();
  const [deleteID, setDeleteID] = useState();
  const [visible, setVisible] = useState(false);
  const [show, setHide] = useState(false);



  const handleEditClick = (course) => {
    setSelectedCourse(course);
    // setEditedName(course.name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveEdit = async () => {
    // Update the course with the edited name
    // console.log("currentEditingCourse", currentEditingCourse);

    var bodyFormData = new FormData();
    bodyFormData.append('id', currentEditingCourse.id);
    bodyFormData.append('title', editedName.title);
    bodyFormData.append('short_desc', editedName.short_desc);
    bodyFormData.append('course_description', editedName.course_description);
    bodyFormData.append('duration', editedName.duration);
    bodyFormData.append('total_classes', editedName.total_classes);
    bodyFormData.append('total_vedios', editedName.total_vedios);
    bodyFormData.append('total_notes', editedName.total_notes);
    bodyFormData.append('skill_level', editedName.skill_level);
    bodyFormData.append('course_language', editedName.course_language);
    bodyFormData.append('category', editedName.category);
    bodyFormData.append('instructor_name', editedName.instructor_name);
    bodyFormData.append('instructor_designation', editedName.instructor_designation);
    bodyFormData.append('instructor_description', editedName.instructor_description);
    bodyFormData.append('instructor_img', editedName.instructor_img);
    bodyFormData.append('course_img', editedName.course_img);
    // console.log("bodyFormData", bodyFormData);

    try {
      // setLoader(true)

      const addInterviewGuide = await UsersService.updateCourse(bodyFormData)      // You can handle the success scenario here if needed

      setVisibleEditCourse(false)
      getTrainingCoarses()
      toast.success('Successfully Created!', {
        position: "top-right"
      })
    } catch (error) {

      toast.error('Something went wrong!', {
        position: "top-right"
      })
      // You can handle the error scenario here if needed
    }


    handleClose();
  };

  useEffect(() => {
    if (currentEditingCourse) {
      setEditedName({
        title: currentEditingCourse?.title,
        short_desc: currentEditingCourse?.short_desc,
        course_description: currentEditingCourse?.course_description,
        duration: currentEditingCourse?.duration,
        total_classes: currentEditingCourse?.total_classes,
        total_vedios: currentEditingCourse?.total_vedios,
        total_notes: currentEditingCourse?.total_notes,
        skill_level: currentEditingCourse?.skill_level,
        course_language: currentEditingCourse?.course_language,
        category: currentEditingCourse?.category,
        instructor_name: currentEditingCourse?.instructor_name,
        instructor_designation: currentEditingCourse?.instructor_designation,
        instructor_description: currentEditingCourse?.instructor_description,
        instructor_img: currentEditingCourse?.instructor_img,
        course_img: currentEditingCourse?.course_img
      })
    }
  }, [currentEditingCourse])

  const handleNameChange = (event) => {
    setEditedName(event.target.value);
  };

  const getTrainingCoarses = async () => {
    const resp = await UsersService.getTrainingCoarse();
    setTrainingCoarses(resp?.data?.data)
    // console.log("resp?.data?.data+++", resp?.data?.data);
  }

  const getTrainingCoarsesClass = async (item) => {
    // let apiData = { course_id: id }
    // const resp = await UsersService.viewCourseClasses(apiData);
    setCoarseID(item?.id)
    setCoarseName(item?.title)
    setHide(true)
    // setTrainingClasses(resp?.data?.data)
  }

  const deleteTrainingCoarses = async (id) => {
    const resp = await UsersService.deleteCoarse(id);
    getTrainingCoarses()
    setDeleteID()
    setVisible(false)
  }

  const addClasses = async (id) => {
    const resp = await UsersService.saveCourseClasses(id);
  }

  useEffect(() => {
    getTrainingCoarses()
  }, [])

  const onTextChange = (value, editor) => {
    setEditedName((prevSettings) => ({
      ...prevSettings,
      course_description: value
    }));
  }






  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      {!show && <CButton className='float-end' onClick={() => { navigate('/training-program') }}>Go Back</CButton>}

      {
        !show && <Box sx={{ width: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {TrainingCoarses?.map((item, index) => {
              return (
                <Grid item xs={4} >
                  <div class="card position-relative"  >
                    <a class="btn btn bg-danger position-absolute top-0 end-0" onClick={() => { setDeleteID(item?.id); setVisible(true) }}>Delete</a>
                    <img style={{ width: "100%", height: "250px" }} src={item?.course_img} class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">{item?.title}</h5>
                      <p class="card-text" style={{
                        overflowY: 'scroll',
                        height: '200px'
                      }}>{ReactHtmlParser(item?.course_description)}</p>
                      <a href="#" class="btn btn-primary">{item?.skill_level}</a>
                      <a href="#" class="btn btn-primary" onClick={() => { setCurrentEditingCourse(item); setVisibleEditCourse(true) }}>Edit Course</a>
                      <a href="#" class="btn btn-primary" onClick={() => { getTrainingCoarsesClass(item) }}>Add Class</a>
                    </div>
                  </div>
                </Grid>)
            })}
          </Grid>
        </Box>
      }

      {
        show && <ViewTrainingClasses setHide={setHide} coarseId={CoarseID} coarseName={coarseName} />
      }
      {/* < Footer /> */}
      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Training Program</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to delete!</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            No
          </CButton>
          <CButton color="primary" onClick={() => deleteTrainingCoarses(deleteID)}>Yes</CButton>
        </CModalFooter>
      </CModal>


      {/* edit course  */}
      <CModal
        visible={visibleEditCourse}
        onClose={() => setVisibleEditCourse(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
        size='lg'
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Edit Training Program</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <SoftBox pt={2} pb={3} px={3}>
            <SoftBox component="form" role="form">
              <SoftBox mb={2}>
                <SoftTypography>Course Title</SoftTypography>
                <SoftInput placeholder="Course Title" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    title: e.target.value
                  }));
                }}
                  value={editedName?.title} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Course description for resume</SoftTypography>
                <SoftInput placeholder="short description" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    short_desc: e.target.value
                  }));
                }}
                  value={editedName?.short_desc} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Course Description</SoftTypography>
                {/* <SoftInput type="textarea" placeholder="long description" onChange={(e) => {
                    setEditedName((prevSettings) => ({
                      ...prevSettings,
                      course_description: e.target.value
                    }));
                  }}
                    value={editedName?.course_description} /> */}
              </SoftBox>
              <RichEditor
                editorDefaultText={editedName.course_description}
                handleUpdate={onTextChange}
              ></RichEditor>

              <SoftBox mb={2}>
                <SoftTypography>Total Number of Days</SoftTypography>
                <SoftInput type="number" placeholder="Duration" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    duration: e.target.value
                  }));
                }}
                  value={editedName?.duration} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Total classes</SoftTypography>
                <SoftInput type="number" placeholder="Total classes" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    total_classes: e.target.value
                  }));
                }}
                  value={editedName?.total_classes} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Total videos</SoftTypography>
                <SoftInput type="number" placeholder="Total videos" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    total_vedios: e.target.value
                  }));
                }}
                  value={editedName?.total_vedios} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Total notes</SoftTypography>
                <SoftInput type="number" placeholder="Total notes" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    total_notes: e.target.value
                  }));
                }}
                  value={editedName?.total_notes} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Skill level</SoftTypography>
                <SoftInput placeholder="Expert , Beginer , Imtermediate" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    skill_level: e.target.value
                  }));
                }}
                  value={editedName?.skill_level} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Course language</SoftTypography>
                <SoftInput placeholder="Course language" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    course_language: e.target.value
                  }));
                }}
                  value={editedName?.course_language} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Category</SoftTypography>
                <SoftInput placeholder="Category" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    category: e.target.value
                  }));
                }}
                  value={editedName?.category} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Instructor name</SoftTypography>
                <SoftInput placeholder="Instructor name" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    instructor_name: e.target.value
                  }));
                }}
                  value={editedName?.instructor_name} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Instructor designation</SoftTypography>
                <SoftInput placeholder="Instructor designation" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    instructor_designation: e.target.value
                  }));
                }}
                  value={editedName?.instructor_designation} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Instructor description</SoftTypography>
                <SoftInput placeholder="Instructor description" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    instructor_description: e.target.value
                  }));
                }}
                  value={editedName?.instructor_description} />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Instructor image</SoftTypography>
                {editedName.instructor_img && (
                  <img
                    src={typeof editedName.instructor_img === "string" ? editedName.instructor_img : URL.createObjectURL(editedName.instructor_img)}
                    alt="Current Instructor"
                    style={{ width: '100px', height: '100px', marginBottom: '10px' }} // Adjust size as needed
                  />
                )}
                <SoftInput type="file" placeholder="Instructor image" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    instructor_img: e.target.files[0]
                  }));
                }}
                />
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography>Course image</SoftTypography>
                {editedName.course_img && (
                  <img
                    src={typeof editedName.course_img === "string" ? editedName.course_img : URL.createObjectURL(editedName.course_img)}
                    alt="Current Instructor"
                    style={{ width: '100px', height: '100px', marginBottom: '10px' }} // Adjust size as needed
                  />
                )}
                <SoftInput type="file" placeholder="Course image" onChange={(e) => {
                  setEditedName((prevSettings) => ({
                    ...prevSettings,
                    course_img: e.target.files[0]
                  }));
                }}
                />
              </SoftBox>

              <SoftBox mb={2}>
                <CButton onClick={() => { handleSaveEdit() }}>Save Edits</CButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisibleEditCourse(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </DashboardLayout >
  );
};

export default TrainingProgramClass;

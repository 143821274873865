import React, { useState } from 'react'
import { CBadge, CButton, CCardBody, CCollapse, CSmartTable } from '@coreui/react-pro'

import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { RiDeleteBin5Line } from 'react-icons/ri';
import { FiCheckCircle } from "react-icons/fi";
import { UsersService } from 'services/UsersAuth';
import RazorpayPaymentButton from 'services/payment/RazorpayPaymentButton';
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";


const TrainerTableData = ({ columns, data, changeStatus, changeStatusOfTrainer, startDate, endDate, MentorFormPopup }) => {
  const usersData = [
    { id: 1, first_name: 'John', last_name: 'Doe', email_id: 'john12@mail.com', Phone_Number: '8834561111', location: 'Chicago', profession: 'Sr. Java Developer', interestedInWhatTrainingProgram: 'Full Stack Pogram' },
    { id: 2, first_name: 'Samppa', last_name: ' Nori', email_id: 'Samppa34@mail.com', Phone_Number: '8830061111', location: 'Houston', profession: 'Sr. Python Developer', interestedInWhatTrainingProgram: 'Machine Learning Program' },
    { id: 3, first_name: 'John', last_name: 'Doe', email_id: 'john12@mail.com', Phone_Number: '8904561111', location: 'Columbus', profession: 'CyberSecurity Manager', interestedInWhatTrainingProgram: 'Application Security Program' },
    { id: 4, first_name: 'Samppa', last_name: ' Nori', email_id: 'Samppa34@mail.com', Phone_Number: '9134561111', location: 'Austin', profession: 'Penetration Tester', interestedInWhatTrainingProgram: 'CyberSecurity Program' },
    { id: 5, first_name: 'John', last_name: 'Doe', email_id: 'john12@mail.com', Phone_Number: '8834561221', location: 'Phoenix', profession: 'Sr. Java Developer', interestedInWhatTrainingProgram: 'Full Stack Pogram' },
    { id: 6, first_name: 'Samppa', last_name: 'Nori', email_id: 'Samppa34@mail.com', Phone_Number: '8834577111', location: 'Chicago', profession: 'Sr. Java Developer', interestedInWhatTrainingProgram: 'Full Stack Pogram' },
    { id: 7, first_name: 'John', last_name: 'Doe', email_id: 'john12@mail.com', Phone_Number: '8904561111', location: 'Columbus', profession: 'Sr. Python Developer', interestedInWhatTrainingProgram: 'Machine Learning Program' },
    { id: 8, first_name: 'Samppa', last_name: ' Nori', email_id: 'Samppa34@mail.com', Phone_Number: '9134561111', location: 'Austin', profession: 'Penetration Tester', interestedInWhatTrainingProgram: 'CyberSecurity Program' }
  ]

  // function gg() {
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, utils.json_to_sheet(data));
  //   writeFileXLSX(wb, "data.xlsx");
  // }
  const [details, setDetails] = useState([])
  // const columns = [
  //   { key: 'first_name', _style: { width: '12%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'last_name', _style: { width: '12%', fontSize: '16px'}, filter: false, sorter: false, },
  //   { key: 'email_id', _style: { width: '15%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'Phone_Number', _style: { width: '15%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'location', _style: { width: '10%', fontSize: '16px'}, filter: false, sorter: false, },
  //   { key: 'profession', _style: { width: '15%', fontSize: '16px' },filter: false,  sorter: false,},
  //   { key: 'interestedInWhatTrainingProgram', _style: { width: '30%', fontSize: '16px' }, filter: false, sorter: false, },
  //   // { key: 'status', _style: { width: '30%' }, filter: false, sorter: false, },
  //   { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
  // ]

  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 'Inactive':
        return 'secondary'
      case 'Pending':
        return 'warning'
      case 0:
        return 'danger'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  return (
    <>
      {/* <button onClick={gg}>EXPORT DATA</button> */}
      <CSmartTable
        checkboxSelection
        sorterValue={{ column: 'name', state: 'asc' }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
          className: 'add-this-class',
          responsive: true,
        }}
        activePage={1}
        // footer
        action
        items={data}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={5}
        columnSorter
        // columnFilterValue={{
        //   created_at: (date) =>
        //     new Date(Date.parse(date)) >= startDate && new Date(Date.parse(date)) <= endDate,
        // }}
        pagination
        scopedColumns={{
          created_at: (item) => (
            <td>
              <CCardBody >
                {moment(item.created_at).format("MM/DD/YYYY")}
              </CCardBody>
            </td>
          ),
          dob: (item) => (
            <td>
              <CCardBody >
                {moment(item.dob).format("MM/DD/YYYY")}
              </CCardBody>
            </td>
          ),
          status: (item) => (

            <td>
              <CBadge color={getBadge(item.status)} onClick={() => { changeStatusOfTrainer(item.id, item.status == 1 ? 2 : 1) }}>{item.status == 1 ? 'Active' : 'Deactive'}</CBadge>
            </td>
          ),
          show_details: (item) => {
            return (

              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(item.id)
                  }}
                >
                  {details.includes(item.id) ? < BiSolidShow /> : <BiSolidHide />}
                </CButton>
              </td>
            )
          },
          details: (item) => {
            return (

              <CCollapse visible={details.includes(item.id)}>
                <CCardBody >
                  <h6>{item.username}</h6>
                  <p className="text-muted" style={{ fontSize: '14px' }}>User since: {item.registered}</p>
                  <CButton size="sm" color="info" onClick={() => { MentorFormPopup(item) }}>
                    {/* <FiCheckCircle /> */}
                    <FaRegEdit />
                  </CButton>
                  {/* <CButton size="sm" color="danger" className="ml-1">
                    <RiDeleteBin5Line />
                  </CButton> */}
                </CCardBody>
              </CCollapse>
            )
          },
        }}


        tableBodyProps={{
          className: 'align-middle',
          style: { fontSize: '13px' }
        }}
      />
      {/* <RazorpayPaymentButton /> */}
    </>
  )
}

export default TrainerTableData


/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CCardHeader,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CDateRangePicker
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
// import { utils, writeFileXLSX } from "xlsx";

// Data
import AdminTableData from "layouts/contact/Data/AdminTableData";
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
// import jsPDF from 'jspdf'
// import autoTable from 'jspdf-autotable'
import { useEffect, useState } from "react";
import { UsersService } from "services/UsersAuth";
import moment from 'moment';

function Contactus() {

  const [admins, setAdmins] = useState();
  const [visible, setVisible] = useState(false)
  const [visibleDetailModal, setVisibleDetailModal] = useState(false)
  const [adminToDelete, setAdminToDelete] = useState()
  const [adminDetailToShow, setAdminDetailToShow] = useState()
  const [startDate, setStartDate] = useState(new Date('2022-01-01'))
  const [endDate, setEndDate] = useState(new Date('2022-03-19'))
  const minDate = new Date(
    Math.min(
      admins?.map((element) => {
        return new Date(Date.parse(element.created_at))
      }),
    ),
  )
  const maxDate = new Date(
    Math.max(
      admins?.map((element) => {
        return new Date(Date.parse(element.created_at))
      }),
    ),
  )


  useEffect(() => {
    getUsersDetail()
  }, [])

  const deleteAdminById = async () => {
    const deleteCom = await UsersService.deleteContactUs(adminToDelete)
    await getUsersDetail()
    setVisible(false)
  }

  const deleteAdminConfirmation = (id) => {
    setAdminToDelete(id)
    setVisible(true)
  }

  const showDetail = (data) => {
    setAdminDetailToShow(data)
    setVisibleDetailModal(true)
  }


  const changeStatus = async (id, status) => {
    await UsersService.ActivateDactivateUser(id, status)
    await getUsersDetail()
  }

  const getUsersDetail = async () => {
    const adminList = await UsersService?.viewContactus();

    setAdmins(adminList?.data?.data)
  }

  // const downloadPdf = ()=>{
  //   const doc = new jsPDF()

  // autoTable(doc, {
  //   head: [columns],
  //   body: [rows],
  // })
  // doc.save('table.pdf')
  // }


  const columns = [
    { key: 'id', _style: { width: '4%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'fname', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'lname', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'email_id', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'user_message', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'contact_subject', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'organization', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'mobile_no', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'user_message', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },

    { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
  ]

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />

        <SoftBox py={3}>
          <SoftBox mb={3}>
            <CRow>
              <CCol xs={12}>
                <CCard className="mb-4">
                  <CCardBody>
                    <AdminTableData columns={columns} data={admins} changeStatus={changeStatus} deleteAdminConfirmation={deleteAdminConfirmation} showDetail={showDetail} />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </SoftBox>

          <CRow>
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardBody>
                  {/* <usersTableData /> */}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </SoftBox>
        <Footer />
      </DashboardLayout>
      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to delete!</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            No
          </CButton>
          <CButton color="primary" onClick={() => deleteAdminById()}>Yes</CButton>
        </CModalFooter>
      </CModal>

      <CModal
        visible={visibleDetailModal}
        onClose={() => setVisibleDetailModal(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p style={{
            overflowY: 'scroll',
            height: '300px'
          }}>{adminDetailToShow?.user_message}</p>
        </CModalBody>
        <CModalFooter>
          {/* <CButton color="secondary" onClick={() => setVisibleDetailModal(false)}>
            No
          </CButton> */}
          <CButton color="primary" onClick={() => setVisibleDetailModal(false)}>Close</CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default Contactus;


import React, { useState } from 'react'
import { CBadge, CButton, CCardBody, CCollapse, CSmartTable } from '@coreui/react-pro'
// import { utils, writeFileXLSX } from "xlsx";

// import data from "./data"
import tooltip from 'assets/theme/components/tooltip';
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { RiDeleteBin5Line } from 'react-icons/ri';
import { FiCheckCircle } from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";

import moment from "moment";
import StudentEditForm from './StudentEditForm';

const SmartTableBasicExample = ({ columns, data, changeStatusConfirmation, startDate, endDate, StudentFormPopup }) => {

  // function gg() {
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, utils.json_to_sheet(data));
  //   writeFileXLSX(wb, "data.xlsx");
  // }
  const [details, setDetails] = useState([])
  const [VisibleForm, setVisibleForm] = useState(false)
  // const columns = [
  //   { key: 'first_name', _style: { width: '15%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'last_name', _style: { width: '15%', fontSize: '16px'}, filter: false, sorter: false, },
  //   { key: 'university_email_id', _style: { width: '20%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'email_id', _style: { width: '20%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'Phone_Number', _style: { width: '15%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'Linkedin_Profile', _style: { width: '20%', fontSize: '16px' },filter: false,  sorter: false,},
  //   { key: 'Training_Program', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'start_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
  //   { key: 'end_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
  //   // { key: 'role', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
  //   // { key: 'status', _style: { width: '30%' }, filter: false, sorter: false, },
  //   { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
  // ]
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 'Inactive':
        return 'secondary'
      case 'Pending':
        return 'warning'
      case 0:
        return 'danger'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }


  const openEditForm = () => {
    setVisibleForm(true)
  }

  const closeEditForm = () => {
    setVisibleForm(false)
  }

  return (
    <>
      {/* <button onClick={gg}>EXPORT DATA</button> */}
      {VisibleForm ? <StudentEditForm back={() => { closeEditForm() }} /> :
        <div style={{ overflowX: 'auto' }}>
          <CSmartTable
            checkboxSelection
            sorterValue={{ column: 'name', state: 'asc' }}
            clickableRows
            tableProps={{
              striped: true,
              hover: true,
              className: 'add-this-class',
              responsive: true,
            }}
            activePage={1}
            // columnFilterValue={{
            //   created_at: (date) =>
            //     new Date(Date.parse(date)) >= startDate && new Date(Date.parse(date)) <= endDate,
            // }}
            // footer
            action
            items={data}
            columns={columns}
            columnFilter
            tableFilter
            cleaner
            itemsPerPageSelect
            itemsPerPage={5}
            columnSorter
            pagination
            scopedColumns={{
              educations: (item) => (
                <td>
                  <CCardBody >
                    {item.educations.length > 0 && item.educations[item.educations.length - 1].degreename}
                  </CCardBody>
                </td>
              ),
              Training_program_1: (item) => (
                <td>
                  <CCardBody >
                    {item?.courses?.length > 0 && JSON.parse(item.courses[0])?.title}
                  </CCardBody>
                </td>
              ),
              Training_program_2: (item) => (
                <td>
                  <CCardBody >
                    {item?.courses?.length > 1 && JSON.parse(item.courses[1]).title}
                  </CCardBody>
                </td>
              ),
              university: (item) => (
                <td>
                  <CCardBody >
                    {item.educations.length > 0 && item.educations[item.educations.length - 1].university}
                  </CCardBody>
                </td>
              ),
              created_at: (item) => (
                <td>
                  <CCardBody >
                    {/* {item.created_at} */}
                    {moment(item.created_at).format("MM/DD/YYYY")}
                  </CCardBody>
                </td>
              ),
              dob: (item) => (
                <td>
                  <CCardBody >
                    {/* {item.created_at} */}
                    {moment(item.dob).format("MM/DD/YYYY")}
                  </CCardBody>
                </td>
              ),
              status: (item) => (
                <td>
                  <CBadge color={getBadge(item.status)} onClick={() => { changeStatusConfirmation(item.id, item.status == 1 ? 2 : 1) }}>{item.status == 1 ? 'Active' : 'Dactive'}</CBadge>
                </td>
              ),
              show_details: (item) => {
                return (

                  <td className="py-2">
                    <CButton
                      color="primary"
                      variant="outline"
                      shape="square"
                      size="sm"
                      onClick={() => {
                        toggleDetails(item.id)
                      }}
                    >

                      {details.includes(item.id) ? < BiSolidShow /> : <BiSolidHide />}
                    </CButton>
                  </td>
                )
              },
              details: (item) => {
                return (

                  <CCollapse visible={details.includes(item.id)}>
                    <CCardBody >
                      <p className="text-muted" style={{ fontSize: '14px' }}>User:{item.fname} {item.registered}</p>
                      {/* <h6>{item.fname}</h6> */}
                      <CButton size="sm" color="info" onClick={() => { StudentFormPopup(item) }}>
                        {/* <FiCheckCircle  /> */}
                        <FaRegEdit />
                      </CButton>
                      {/* <CButton size="sm" color="danger" className="ml-1">
                        <RiDeleteBin5Line />
                      </CButton> */}
                    </CCardBody>
                  </CCollapse>
                )
              },
            }}


            tableBodyProps={{
              className: 'align-middle',
              style: { fontSize: '13px' }
            }}
          />
        </div>
      }
    </>
  )
}

export default SmartTableBasicExample

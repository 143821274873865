import React, { useState, useEffect } from 'react'
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftPieChart from 'components/SoftPieChart'
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import { UsersService } from 'services/UsersAuth';


const RecruiterGraph = () => {
      const [graphData, setGraphData] = useState();

      useEffect(() => {
            getUsersDetail()
      }, [])

      const getUsersDetail = async () => {
            let ApiData = {
                  user_type: "recruiter",
                  // year: year
            }
            const GraphData = await UsersService?.getDashboardGraphData(ApiData);
            setGraphData(GraphData?.data?.data)
      }
      const data = [
            // {
            //       id: 0, value: graphData?.recruiterJobs?.total_jobs
            //       , label: 'Total jobs'
            // },
            {
                  id: 1, value: graphData?.recruiterJobs?.new_jobs
                  , label: 'New jobs'
            },
            {
                  id: 2, value: graphData?.recruiterJobs?.
                        completed_jobs
                  , label: 'completed'
            },
      ];

      const gradientLineChartData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            datasets: [
                  {
                        label: "Revenue by jobs",
                        color: "info",
                        data: graphData?.jobsRevenue,
                  }
            ],
      };
      return (
            <SoftBox mb={3}>

                  <Grid container spacing={3}>
                        <Grid item xs={12} lg={5}>
                              {data?.length > 0 && <SoftPieChart data={data && data} />}
                        </Grid>
                        <Grid item xs={12} lg={7}>
                              <GradientLineChart
                                    title="Recruiters Revenue"
                                    description={
                                          <SoftBox display="flex" alignItems="center">
                                          </SoftBox>
                                    }
                                    height="20.25rem"
                                    chart={gradientLineChartData && gradientLineChartData}
                              />
                        </Grid>
                  </Grid>
            </SoftBox>
      )
}

export default RecruiterGraph
/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import {
      // CAvatar,
      CButton,
      CCard,
      CCardBody,
      // CCardSubtitle,
      // CCardTitle,
      CCol,
      // CDropdown,
      // CDropdownItem,
      // CDropdownMenu,
      // CDropdownToggle,
      // CProgress,
      CRow,
      CCardHeader,
      // CTable,
      // CTableBody,
      // CTableDataCell,
      // CTableHead,
      // CTableHeaderCell,
      // CTableRow,
      // CWidgetStatsA,
      // CForm,
      // CFormLabel,
      // CFormInput,
      // CFormText,
      // CFormCheck,
      CModal,
      CModalHeader,
      CModalBody,
      CModalFooter,
      CModalTitle
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
// import { utils, writeFileXLSX } from "xlsx";

// Data
import AdminTableData from "layouts/admin/Data/AdminTableData";
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
// import jsPDF from 'jspdf'
// import autoTable from 'jspdf-autotable'
import { useEffect, useState } from "react";
import { UsersService } from "services/UsersAuth";
import SessionTableData from "./data/SessionTableData";
function Sessions() {

      const [admins, setAdmins] = useState();
      const [visible, setVisible] = useState(false)
      const [adminToDelete, setAdminToDelete] = useState()



      useEffect(() => {
            getUsersDetail()
      }, [])

      const deleteAdminById = async () => {
            const deleteCom = await UsersService.deleteAdmin(adminToDelete, 8)
            await getUsersDetail()
            setVisible(false)
      }

      const deleteAdminConfirmation = (id) => {
            setAdminToDelete(id)
            setVisible(true)
      }


      const changeStatus = async (id, status) => {
            await UsersService.ActivateDactivateUser(id, status)
            await getUsersDetail()
      }

      const getUsersDetail = async () => {
            const adminList = await UsersService?.Session();
            setAdmins(adminList?.data?.data)
      }

      // const downloadPdf = ()=>{
      //   const doc = new jsPDF()

      // autoTable(doc, {
      //   head: [columns],
      //   body: [rows],
      // })
      // doc.save('table.pdf')
      // }

      // Student Feedback Stars and notes
      // Notes (Feedback from mentor)

      const columns = [
            { key: 'payment_status', _style: { width: '15%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'id', _style: { width: '4%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'mentor_fname', label: 'Mentor fname', _style: { width: '8%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'mentor_lname', label: 'Mentor lname', _style: { width: '8%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'mentor_email', _style: { width: '15%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'student_fname', label: 'Student fname', _style: { width: '8%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'student_lname', label: 'Mentor lname', _style: { width: '8%', fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'student_fname', _style: { width: '15%', fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'student_lname', _style: { width: '15%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'student_email', _style: { width: '15%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'recorded_link', _style: { width: '15%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'paymentid', _style: { width: '15%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'amount', label: "Fees ($)", _style: { width: '15%', fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'payment_status', label: 'Payment', _style: { width: '7%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'meeting_link', label: 'Meet-link', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'slot_time', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'bookig_date', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'start_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
            // { key: 'end_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
            // { key: 'role', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
            // { key: 'amount', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
      ]

      return (
            <>
                  <DashboardLayout>
                        <DashboardNavbar />

                        <SoftBox py={3}>
                              <SoftBox mb={3}>
                                    <CRow>
                                          <CCol xs={12}>
                                                <CCard className="mb-4">
                                                      <CCardBody>
                                                            <SessionTableData columns={columns} data={admins} changeStatus={changeStatus} deleteAdminConfirmation={deleteAdminConfirmation} />
                                                      </CCardBody>
                                                </CCard>
                                          </CCol>
                                    </CRow>
                              </SoftBox>

                              <CRow>
                                    <CCol xs={12}>
                                          <CCard className="mb-4">
                                                <CCardBody>
                                                      {/* <usersTableData /> */}
                                                </CCardBody>
                                          </CCard>
                                    </CCol>
                              </CRow>
                        </SoftBox>
                        <Footer />
                  </DashboardLayout>
                  <CModal
                        visible={visible}
                        onClose={() => setVisible(false)}
                        aria-labelledby="LiveDemoExampleLabel"
                        alignment="center"
                  >
                        <CModalHeader>
                              <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                              <p>Are you sure you want to delete!</p>
                        </CModalBody>
                        <CModalFooter>
                              <CButton color="secondary" onClick={() => setVisible(false)}>
                                    No
                              </CButton>
                              <CButton color="primary" onClick={() => deleteAdminById()}>Yes</CButton>
                        </CModalFooter>
                  </CModal>
            </>
      );
}

export default Sessions;


import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function ProtectedRouter({ Component, path }) {
      const isAuthenticated = localStorage.getItem('access_token') ? true : false;
      const navigate = useNavigate();
      const [loading, setLoading] = useState(true);

      useEffect(() => {
            const checkAuth = () => {
                  if (!isAuthenticated) {
                        navigate(path || "");
                  }
            };
            checkAuth();
            setLoading(false);
      }, [isAuthenticated, path]);


      if (loading) {
            // While checking for token, show nothing or a loader
            return
      }



      if (isAuthenticated) {
            return (
                  <>
                        <Component />
                  </>
            );
      }
}